import axios from "axios";

const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api",
});

class AccessDetailService {
    getDoctorsInfo() {
        return authAxios.get(`/healthcareEstablishments`, {
            withCredentials: true,
        });
    }
    addDoctorsInfo(data) {
        return authAxios.post(`/healthcareEstablishments`, data, {
            withCredentials: true,
        });
    }
    getOpeningHours() {
        return authAxios.get(`/openingHours`, {
            withCredentials: true,
        });
    }
    addOpeningHours(data) {
        return authAxios.post(`/openingHours`, data, {
            withCredentials: true,
        });
    }
}

export default new AccessDetailService
