import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, FormFeedback, FormGroup, Input, Label, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ModalComponent from '../../components/Monsite/ModalComponent'
import SideMenu from '../../components/Monsite/SideMenu'
import ToastComponent from '../../components/Monsite/ToastComponent'
import Header from '../../helpers/Header/Header'
import warningIcon from '../../images/icons/warningIcon.svg'
import VisualParamService from '../../services/VisualParamService'
import Store from '../../store/configStore';

class SubDomain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            final_subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            is_loaded: false,
            showModal: false,
            existingSubdomain: false,
            existingDoctor: false,
            subdomain: "",
            validSubdomain: null
        }
    }
    modalHeader = () => {
        return (
            <div>

            </div>
        )
    }
    modalBody = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <div style={{ height: 158, width: 158, backgroundColor: "rgba(249, 170, 0, 0.08)", borderRadius: 16, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={warningIcon} />
                </div>
                <h3>êtes-vous sûr ?</h3>
                <p>
                    Si vous réservez votre nom de domaine, vous ne pourrez plus le modifier.
                </p>
            </div>
        )
    }
    modalFooter = () => {
        return (
            <div style={{ justifyItems: "center", width: "100%" }}>
                <Button
                    color="primary"
                    onClick={() => {
                        this.postSubdomain()
                        this.setState({ validSubdomain: null, showModal: false })
                    }}
                >
                    Enregistrer
                </Button>
                <Button
                    outline
                    color="warning"
                    onClick={() => {
                        this.setState({ showModal: false })
                    }}
                >
                    Annuler
                </Button>
            </div>
        )
    }

    componentDidMount() {
        this.getSubdomain()
    }
    getSubdomain = async () => {
        try {
            let response = await VisualParamService.getApplicationParams();
            if (response.data.subDomain) {
                this.setState({
                    existingSubdomain: true,
                    subdomain: response.data.subDomain
                })
            }
            if (response.data.id) {
                this.setState({
                    existingDoctor: true
                })
            }
            this.setState({
                is_loaded: true,
                showModal: false
            })
        } catch (error) {
            console.error('applicationParams', error)
        }
    }

    postSubdomain = async () => {
        let formData = new FormData();
        formData.append("subdomain", this.state.subdomain);
        try {
            await VisualParamService.addsubdomain(formData);
            this.setState({
                existingSubdomain: true
            }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))

        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }
    checkSubdomain = async () => {
        let data = {
            subdomain: this.state.subdomain
        }
        try {
            let response = await VisualParamService.checkingSubdomain(data);
            this.setState({
                validSubdomain: response.data.existingSubdomain
            })
        } catch (error) {

        }
    }

    render() {
        const { showModal } = this.state;
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>
                            {showModal && (
                                <ModalComponent
                                    className="alertModal"
                                    isOpen={showModal}
                                    hideCloseBtn
                                    header={this.modalHeader()}
                                    body={this.modalBody()}
                                    footer={this.modalFooter()}
                                />

                            )}
                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Personnalisation</BreadcrumbItem>
                                        <BreadcrumbItem active>Nom du domaine</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                {this.state.final_subdomain !== null && (
                                    <div>
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.final_subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ margin: 0 }}>
                                        <Row>
                                            <div className="monSite-logoParams">
                                                <Col>
                                                    <div className="monSite-logoParams__title">
                                                        <h3>Insérez un nom de domaine</h3>
                                                    </div>
                                                    <div className="monSite-logoParams__description">
                                                        <p> {!this.state.existingSubdomain ? "Choisissez un nom facile et mémorisable par vos patients" : "Votre nom de domaine est choisi"}</p>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>
                                                            Lien du site web
                                                        </Label>
                                                        <Input
                                                            invalid={this.state.validSubdomain === true}
                                                            valid={this.state.validSubdomain === false}
                                                            isClearable
                                                            type="text"
                                                            disabled={this.state.existingSubdomain}
                                                            value={this.state.subdomain}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    subdomain: e.target.value
                                                                }, () => this.checkSubdomain())
                                                            }}
                                                        ></Input>
                                                        <FormFeedback valid>Super, ce nom est disponible</FormFeedback>
                                                        <FormFeedback invalid >Oups, ce nom est déjà pris, merci de réessayer un autre nom de domaine</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div style={{ marginTop: 29 }}>
                                                        <p>.chezledocteur.net</p>
                                                    </div>
                                                    {!this.state.existingSubdomain && !this.state.validSubdomain && (
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                if(/[^a-zA-Z0-9\-]/.test(this.state.subdomain)){
                                                                    notify({ type: "danger", msg: "le nom du domaine ne doit pas contenir: les espaces( ), les points(.), les tirets (_)", delay: 4000 })
                                                                }else {
                                                                    this.setState({ showModal: true })
                                                                }
                                                            }}
                                                        >
                                                            Réserver
                                                        </Button>
                                                    )}


                                                </Col>
                                            </div>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(SubDomain)
