import axios from "axios";
import authHeader from "./AuthHeader";
import { refresh } from "./AuthService";

const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: authHeader(),
    withCredentials: true
});
class UserService {
    constructor() {

        authAxios.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                if (401 === error.response.status) {
                    
                    // if (error.response.data.message === "code1") {
                    //     localStorage.clear();
                    //     // window.location.href = TOKEN_URL
                    // } else if (error.response.data.message === "code2") {
                    //     const originalRequest = error.config;
                    //     try {
                    //         await refresh();
                    //         window.location.reload();
                    //     } catch (error) {
                    //     }
                    // } else if (error.response.data.message === "code3") {
                    //     localStorage.clear();
                    //     // window.location.href = TOKEN_URL
                    // }
                } else {
                    return Promise.reject(error);
                }
            }
        );
    }

    getUser() {
        return authAxios.get(`/api/authenticated/user`, {
            withCredentials: true,
          });
    }

    getUserWithoutInterceptor() {
        return axios.get(process.env.REACT_APP_API_URL + "/api/authenticated/user");
    }

    logout = async () => {
        try {
            await authAxios.get('/auth/revoke')
            window.location.href = "/auth/login";
        } catch (error) {
            window.location.href = "/auth/login";
        }
    }
}
export default new UserService();