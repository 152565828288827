import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class HealthcareEstablishmentPictureService {
  getAllAttributes(){
    return authAxios.get(`/attributes`, {
      withCredentials: true,
    });
  }
  getPictures() {
    return authAxios.get(`/healthcareEstablishmentPictures`, {
      withCredentials: true,
    });
  }
  addPictures(data){
    return authAxios.post(`/healthcareEstablishmentPictures`, data , {
      withCredentials: true,
    });
  }
  updatePictures(data){
    return authAxios.post(`/healthcareEstablishmentPictures?_method=PATCH`, data , {
      withCredentials: true,
    })
  }
}
export default new HealthcareEstablishmentPictureService();