import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@laazyry/sobrus-design-system'

class ModalComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: this.props.isOpen
        }
    }

    render() {
        const { isOpen } = this.state;
        return (
            <div>
                {isOpen && (
                    <Modal onClose={this.props.onClose} isOpen={isOpen} hideCloseBtn={true} hideHeader={this.props.hideHeader} className={this.props.className}>
                        <ModalHeader>
                            <ModalTitle>{this.props.header}</ModalTitle>
                        </ModalHeader>
                        <ModalBody>
                            {this.props.body}
                        </ModalBody>
                        <ModalFooter>
                            {this.props.footer}
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        )
    }
}

export default ModalComponent
