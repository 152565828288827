import React, {Component} from 'react'
import { SobDragDropContext, Droppable, DroppableContainer, DroppableTitle, Draggable, DraggableItem, dragDropReorder, dragDropMove, Col } from '@laazyry/sobrus-design-system';
import { FiEye } from "react-icons/fi";
import Store from '../../store/configStore';

class DragDrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuItems: this.props?.firstTable,
            selected: this.props?.secondTable,
            workplace: Store.getState()?.workplace?.workplace,
        }
    }

    id2List = {
        droppable: 'menuItems',
        droppable2: 'selected'
    };


    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const menuItems = dragDropReorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { menuItems };

            if (source.droppableId === 'droppable2') {
                state = { selected: menuItems };
            }
            this.props.setSelectedItems(state.selected, this.state.menuItems)
            this.setState(state);
        } else {
            const result = dragDropMove(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                menuItems: result.droppable,
                selected: result.droppable2
            }, ()=>this.props.setSelectedItems(this.state.selected, this.state.menuItems));
            
        }
    };

    render() {
        return (
                <SobDragDropContext onDragEnd={this.onDragEnd}>
                    <Col>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <DroppableContainer
                                    ref={provided.innerRef}
                                    isDraggingOver={snapshot.isDraggingOver}
                                >
                                    <DroppableTitle>Invisible</DroppableTitle>
                                    {
                                        this.state.menuItems.map((item, index) => (
                                            <Draggable
                                                key={index + 1}
                                                draggableId={"list A " + (index + 1)}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <DraggableItem
                                                        ref={provided.innerRef}
                                                        isDragging={snapshot.isDragging}
                                                        draggableStyle={provided.draggableProps.style}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >{item.label}</DraggableItem>
                                                )}
                                            </Draggable>
                                        ))
                                    }

                                </DroppableContainer>
                            )}
                        </Droppable>
                    </Col>

                    <Col>
                        <Droppable droppableId="droppable2">
                            {(provided, snapshot) => (
                                <DroppableContainer
                                    ref={provided.innerRef}
                                    isDraggingOver={snapshot.isDraggingOver}
                                >
                                    <DroppableTitle bgColor="rgba(27, 186, 172, 0.08)" textColor="#1bbaac" icon={<FiEye color="#1bbaac" size={17} />}>Visible</DroppableTitle>
                                    {this.state.selected.map((item, index) => (
                                        <Draggable
                                            key={index + 1}
                                            draggableId={"list B " + (index + 1)}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <DraggableItem
                                                    ref={provided.innerRef}
                                                    isDragging={snapshot.isDragging}
                                                    draggableStyle={provided.draggableProps.style}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >{item.label}</DraggableItem>
                                            )}
                                        </Draggable>
                                    ))}

                                </DroppableContainer>
                            )}
                        </Droppable>
                    </Col>
                
                </SobDragDropContext>
            )
    }
}
export default DragDrop;