const initialState = { workplace: 'clinique' }

function workplace(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_WORKPLACE":
            nextState = {
                ...state,
                workplace: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}
export default workplace;