import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import SideMenu from '../../components/Monsite/SideMenu';
import DragDrop from '../../components/Monsite/DragDrop';
import MenuService from '../../services/MenuService';
import warningIcon from "../../images/icons/warningIcon.svg";
import ModalComponent from '../../components/Monsite/ModalComponent';
import Header from '../../helpers/Header/Header';
import Store from '../../store/configStore';


class Menu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            showAlert: false,
            dataIsChanged: false,
            menuItems: [
                {
                    label: "Accueil",
                    link: "#home",
                    linkType: "InternalLink",
                    order: 1
                },
                {
                    label: Store.getState()?.workplace?.workplace === "clinique" ? "À propos de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "À propos du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "À propos du centre de santé" : "À propos du docteur")),
                    link: "#about",
                    linkType: "InternalLink",
                    order: 2
                },
                {
                    label: "Cursus professionnel",
                    link: "#cursus",
                    linkType: "InternalLink",
                    order: 3
                },
                {
                    label: "Soins et actes",
                    link: "#careServices",
                    linkType: "InternalLink",
                    order: 4
                },
                {
                    label: Store.getState()?.workplace?.workplace === "clinique" ? "Images de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "Images du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "Images du centre de santé" : "Images")),
                    link: "#pictures",
                    linkType: "InternalLink",
                    order: 5
                },
                {
                    label: "Vidéothèque",
                    link: "#videos",
                    linkType: "InternalLink",
                    order: 6
                },
                {
                    label: "Détails d’accès",
                    link: "#details",
                    linkType: "InternalLink",
                    order: 7
                },
                // {
                //     label: "Prendre RDV",
                //     link: "#appointments",
                //     linkType: "InternalLink",
                //     order: 8
                // },
                {
                    label: "Avant/Après",
                    link: "#transformations",
                    linkType: "InternalLink",
                    order: 9
                }
            ],
            selected: [],
            is_loaded: false
        }
    }

    componentDidMount() {
        this.getMenuItems()
    }

    getMenuItems = async () => {
        try {
            let response = await MenuService.getMenuItems();
            
            let newSelected = response.data.map(obj=>{
                if(obj.link==="#about"){
                    return {...obj, label: Store.getState()?.workplace?.workplace === "clinique" ? "À propos de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "À propos du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "À propos du centre de santé" : "À propos du docteur"))};
                }
                if(obj.link==="#pictures"){
                    return {...obj, label: Store.getState()?.workplace?.workplace === "clinique" ? "Images de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "Images du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "Images du centre de santé" : "Images"))};
                }
                return obj;
            })
            
            let newMenuItems = this.state.menuItems.map(obj=>{
                if(obj.link==="#about"){
                    return {...obj, label: Store.getState()?.workplace?.workplace === "clinique" ? "À propos de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "À propos du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "À propos du centre de santé" : "À propos du docteur"))};
                }
                if(obj.link==="#pictures"){
                    return {...obj, label: Store.getState()?.workplace?.workplace === "clinique" ? "Images de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "Images du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "Images du centre de santé" : "Images"))};
                }
                return obj;
            })
            let newTable = newMenuItems.filter((addedItem) => !response.data.some(cartItem => cartItem.link === addedItem.link))

            this.setState({
                selected: newSelected,
                menuItems: newTable,
                is_loaded: true
            })
        } catch (error) {
            console.error('menuItems', error)
        }
    }

    setSelectedItems = (selected, principal) => {
        this.setState({
            selected: selected,
            menuItems: principal,
            dataIsChanged: true,
        })
    }
    addMenuItems = async () => {

        this.setState({ is_loaded: false })
        this.state.selected?.map((item, index) => {
            item.order = index + 1;
        })
        try {
            await MenuService.addMenuItems(this.state.selected);
            this.setState({
                is_loaded: true,
                dataIsChanged: false,

            },
                () => {
                    notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 })
                }
            )
        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }

    handleShowAlert = (data) => {
        this.setState({
            showAlert: data
        })
    }

    alertBody = () => {
        return (
            <div>
                <Row>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                        <div className="monSite-alertIcon" >
                            <img src={warningIcon} />
                        </div>
                        <div className="monSite-stepsTitle">
                            <h4>Enregistrez les changements avant de quitter</h4>
                        </div>
                        <div className="monSite-stepsDescription">
                            <p>Merci d’enregistrer les changements opérés avant de visualiser votre site web</p>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
    alertFooter = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                <Button
                    outline
                    color="warning"
                    onClick={() => {
                        this.setState({
                            showAlert: false
                        })
                    }}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        this.addMenuItems()
                        this.setState({
                            showAlert: false
                        })

                    }}
                >
                    Enregistrer
                </Button>
            </div>
        )
    }
    render() {
        return (

            <div>

                <Header />
                <div className="monSite-container">
                    {this.state.showAlert && (
                        <div className="alertModal">
                            <ModalComponent
                                hideHeader={true}
                                onClose={() => this.setState({ showAlert: false })}
                                isOpen={this.state.showAlert}
                                body={this.alertBody()}
                                footer={this.alertFooter()}
                            />
                        </div>
                    )}
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>


                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Personnalisation</BreadcrumbItem>
                                        <BreadcrumbItem active>Menu principal</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => {
                                            this.addMenuItems()
                                        }}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                            <div className="monSite-dragRow">
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ margin: 0 }}>
                                        <h3>Menu</h3>
                                        <p>Choisissez le classement du menu au niveau de votre site web</p>
                                        <DragDrop firstTable={this.state.menuItems} secondTable={this.state.selected} setSelectedItems={this.setSelectedItems} />
                                    </Card>
                                </Col>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Menu
