import { SettingsAccordion, SettingsAccordionItem, SettingsAccordionItemButton, SettingsAccordionItemHeading, SettingsAccordionPanel, SettingsAccordionPanelItem } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import personnalisationIcon from '../../images/icons/personnalisationIcon.svg'
import siteContentIcon from '../../images/icons/siteContentIcon.svg'
import headingsIcon from '../../images/icons/headingsIcon.svg'
import userManual from '../../images/icons/userManual.svg'
import { withRouter } from 'react-router-dom'
import Store from '../../store/configStore';

class SideMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: false,
            settingsAccordion: [
                {
                    SettingsAccordionItemHeading: "Personnalisation",
                    icon: personnalisationIcon,
                    path:"/settings/customization",
                    SettingsAccordionPanelItems: [
                        {
                            label: "Lieu d'exercice et identité visuelle",
                            path: "/settings/customization/visualParams"
                        },
                        {
                            label: "Menu principal",
                            path: "/settings/customization/menus"
                        },
                        {
                            label: "Nom du domaine",
                            path: "/settings/customization/subdomains"
                        },
                        {
                            label: "Bandeau",
                            path: "/settings/customization/banner"
                        }
                    ]
                },
                {
                    SettingsAccordionItemHeading: "Contenu du site",
                    icon: siteContentIcon,
                    path:"/settings/siteContent",
                    SettingsAccordionPanelItems: [
                        {
                            label: "Détails d’accès",
                            path: "/settings/siteContent/accessDetails"
                        },
                        {
                            label: "Sliders",
                            path: "/settings/siteContent/slides"
                        },
                        {
                            label: Store.getState()?.workplace?.workplace === "clinique" ? "Présentation de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "Présentation du docteur" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "Présentation du centre de santé" : "Présentation du docteur")),
                            path: "/settings/siteContent/presentations"
                        },
                        {
                            label: "Soins et actes",
                            path: "/settings/siteContent/careservices"
                        },
                        {
                            label: "Cursus professionnel",
                            path: "/settings/siteContent/cursus"
                        },
                        {
                            label: "Avant / Après",
                            path: "/settings/siteContent/transformations"
                        },
                        {
                            label: Store.getState()?.workplace?.workplace === "clinique" ? "Images de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "Images du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "Images du centre de santé" : "Images")),
                            path: "/settings/siteContent/healthcareEstablishmentImages"
                        },
                        {
                            label: "Vidéothèque",
                            path: "/settings/siteContent/videos"
                        },
                        // {
                        //     label: "Prendre RDV",
                        //     path: "/settings/siteContent/chezledocteur"
                        // },
                        {
                            label: "Réseaux sociaux",
                            path: "/settings/siteContent/socialMedias"
                        },
                    ]
                },
                {
                    SettingsAccordionItemHeading: "Organisation des rubriques",
                    icon: headingsIcon,
                    path:"/settings/layoutHeadings",
                    SettingsAccordionPanelItems: [
                        {
                            label: "Organisation",
                            path: "/settings/layoutHeadings/headings"
                        }
                    ]
                },
            ]
        }
    }

    render() {
        const { settingsAccordion, isActive } = this.state;
        return (
            <div>
                <div className="monSite-progressionButton">
                    <div className="monSite-sideMenuItem">
                        <div onClick={()=>{
                            this.props.history.push('/settings')
                        }}>
                            <img src={userManual} style={{ marginRight: "1rem", position: "relative", top: "5px", width:"26px"}} />
                            Guide d’utilisation
                        </div>
                    </div>
                </div>
                <SettingsAccordion allowZeroExpanded preExpanded={["/" + this.props.location.pathname.split('/')[1]?.toLowerCase() + "/" + this.props.location.pathname.split('/')[2]?.toLowerCase()]}>
                    {settingsAccordion.map((settingAccordion, index) => {
                        return (
                            <SettingsAccordionItem uuid={settingAccordion.path.toLowerCase()}>
                                <SettingsAccordionItemHeading>
                                    <SettingsAccordionItemButton>
                                        <div className="monSite-sideMenuItem">
                                            <img src={settingAccordion.icon} style={{ marginRight: "1rem", width:"26px" }} />
                                            {settingAccordion.SettingsAccordionItemHeading}
                                        </div>
                                        
                                    </SettingsAccordionItemButton>
                                </SettingsAccordionItemHeading>
                                <SettingsAccordionPanel >
                                    {settingAccordion.SettingsAccordionPanelItems.map((settingAccordionPanelItem) => {
                                        return (

                                            <SettingsAccordionPanelItem
                                                active={this.props.location.pathname.toLowerCase() === settingAccordionPanelItem.path.toLowerCase()}
                                                onClick={() => {
                                                    // if(this.props.dataIsChanged){
                                                    //     this.props.handleShowAlert(true)
                                                    // }else {
                                                        this.props.history.push(settingAccordionPanelItem.path)
                                                    // }
                                                }}
                                            >
                                                {settingAccordionPanelItem.label}
                                            </SettingsAccordionPanelItem>
                                        )
                                    })}
                                </SettingsAccordionPanel>
                            </SettingsAccordionItem>

                        )
                    })}
                </SettingsAccordion>
            </div>
        )
    }
}

export default withRouter(SideMenu)
