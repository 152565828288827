import React, { Component } from 'react'
import logo from "../images/logos/logo.png";
import { Link } from 'react-router-dom';
import { Button } from '@laazyry/sobrus-design-system';
import error403 from '../images/error403.svg';

class ErrorPage extends Component {
    render() {
        return (
            <div>
                <div className="monSite-notFoundHeader">
                    <Link to={"/"}>
                        <img src={logo} width="100" alt="logo" />
                    </Link>
                </div>
                <div className="monSite-notFoundBody">
                    <img src={error403} alt="not_found" />
                    <h3>
                        Error 403
                    </h3>
                    <p className="errorSubTitle">
                        Accès non-autorisé
                    </p>
                    <p className="errorDesc">
                        Votre compte MonSite Sobrus semble être introuvable ou désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@soburs.com).
                    </p>
                    <Link to={"/"}>
                        <Button
                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                            // outline
                            color="primary"
                        // className="btn-outline"
                        // onClick={() => this.postDoctorData()}
                        >
                            Accueil
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
}
export default ErrorPage