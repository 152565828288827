import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import "../../../../css/global.css"

class UserManual extends Component {
    render() {
        return (
            <div className="monSite-container">
                <div className="monSite-breadcrumb">
                    <Breadcrumb>
                        <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                        <BreadcrumbBody>
                            <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                            <BreadcrumbItem active>Guide d'utilisation</BreadcrumbItem>
                        </BreadcrumbBody>
                    </Breadcrumb>
                    <div>
                        <Button
                            style={{ marginLeft: "0.5rem" }}
                            outline
                            color="primary"
                            // className="btn-outline"
                            // onClick={() => selectModal()}
                        >
                            Visualiser
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(UserManual)
