import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class SocialMediaService {
  getSocialMedias() {
    return authAxios.get(`/socialMedias`, {
      withCredentials: true,
    });
  }
  addSocialMedias(data){
    return authAxios.post(`/socialMedias`, data , {
      withCredentials: true,
    });
  }
}
export default new SocialMediaService();