import { ImagePicker, Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, FormGroup, FormText, Input, Label, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import SideMenu from '../../components/Monsite/SideMenu'
import Header from '../../helpers/Header/Header'
import PresentationService from '../../services/PresentationService'
import Store from '../../store/configStore';

class Presentation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            workplace: Store.getState()?.workplace?.workplace,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            BASE_URL: process.env.REACT_APP_API_URL,
            imagesData: [],
            id: "",
            title: "",
            description: "",
            profilPicture: "",
            existingDoctor: false,
            is_loaded: false
        }
    }
    componentDidMount() {
        this.getPresentation();
        console.log(this.state.imagesData)
    }

    getPresentation = async () => {
        try {
            let response = await PresentationService.getPresentation();
            if (response.data !== "") {
                this.setState({
                    title: response.data.title,
                    description: response.data.description,
                    profilPicture: response.data.profilPicture,
                    id: response.data.id,
                    imagesData: response.data.profilPicture ? [
                        ...this.state.imagesData,
                        {
                            ...this.state.imagesData[0],
                            source: `${this.state.BASE_URL}/uploads/images/profils/${response.data.profilPicture}`,
                            file: response.data.profilPicture,
                        }] : [],
                    existingDoctor: true
                }, () => console.log(this.state.imagesData))
            }
            this.setState({
                is_loaded: true
            })
        } catch (error) {

        }
    }

    postPresentation = async () => {
        this.setState({ is_loaded: false })
        console.log(this.state.imagesData.length);
        let formData = new FormData();

        if (this.state.title) {
            formData.append("title", this.state.title);
        }
        if (this.state.description) {
            formData.append("description", this.state.description);
        }

        if (this.state.imagesData && this.state.imagesData[0]?.file) {
            formData.append("profilPicture", this.state.imagesData[0].file);
        }

        try {
            await PresentationService.addPresentation(formData);
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))
        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }


    render() {
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>

                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>{this.state.workplace === "cabinet" ? "Présentation du cabinet" : (this.state.workplace === "centre de santé" ? "Présentation du centre de santé" :(this.state.workplace === "clinique" ? "Présentation de la clinique" : "Présentation du docteur"))}</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => this.postPresentation()}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                {this.state.is_loaded && (
                                    <Col>
                                        <Card style={{ margin: 0 }}>
                                            <h3>Présentation</h3>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <div style={{ marginTop: "1rem" }}>
                                                            <Label>
                                                                Titre
                                                            </Label>
                                                            <Input
                                                                value={this.state.title}
                                                                onChange={(e) => this.setState({
                                                                    title: e.target.value
                                                                })}
                                                                type="text"
                                                                placeHolder="Votre titre ici …"
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: "1rem" }}>
                                                            <Label>
                                                                Description
                                                            </Label>
                                                            <Input
                                                                value={this.state.description}
                                                                onChange={(e) => this.setState({
                                                                    description: e.target.value
                                                                })}
                                                                type="textarea"
                                                                rows="5"
                                                                placeHolder="Votre description ici …"
                                                            />
                                                        </div>

                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <p>{this.state.workplace === "cabinet" ? "Photo du cabinet" : (this.state.workplace === "centre de santé" ? "Photo du centre de santé" :(this.state.workplace === "clinique" ? "Photo de la clinique" : "Photo du docteur"))}</p>
                                                    <ImagePicker
                                                        horizontal={false}
                                                        desc="Sélectionnez ou Glissez-déposez la photo du docteur ici .."
                                                        initialState={this.state.imagesData}
                                                        imagesData={this.state.imagesData}
                                                        setImagesData={(imagesData) => this.setState({ imagesData })}
                                                        onRemove={(callback) => callback()}
                                                    ></ImagePicker>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )}
                            </Row>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Presentation
