import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, CheckBox, Col, DatePicker, FormGroup, IconButton, Input, InputSelect, Label, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import SideMenu from '../../components/Monsite/SideMenu'
import CursusService from '../../services/CursusService'
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import moment from 'moment';
import Header from '../../helpers/Header/Header';
import Store from '../../store/configStore';

class Cursus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            is_loaded: false,
            workplace: Store.getState()?.workplace?.workplace,
            cursusRows: [{
                cursusType: "",
                label: "",
                startDate: "",
                endDate: "",
                description: "",
                tillNow: false
            }],

            cursusRow: {
                cursusType: "",
                label: "",
                startDate: "",
                endDate: "",
                description: "",
                tillNow: false
            }
        }
    }

    getCursusRow = () => {
        if(this.state.cursusRow.tillNow){
            this.setState({
                cursusRow: {
                    ...this.state.cursusRow,
                    endDate: ""
                }
            })
        }
        this.state.cursusRows.unshift(this.state.cursusRow);
        this.setState({
            cursusRow: {
                cursusType: "",
                label: "",
                startDate: "",
                endDate: "",
                description: "",
                tillNow: false
            }
        })
    }

    componentDidMount() {
        this.getCursus();
    }

    getCursus = async () => {
        try {
            let response = await CursusService.getCursus();
            this.setState({
                cursusRows: response.data
            })
            // }
            this.setState({ is_loaded: true })
        } catch (error) {

        }
    }

    postSocialMedias = async () => {
        this.setState({ is_loaded: false })
        try {
            await CursusService.addCursus(this.state.cursusRows);
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))

        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }

    handleCursusChange(index, field, value) {
        let cursus = this.state.cursusRows;
        cursus[index] = { ...cursus[index] };
        cursus[index][field] = value;
        this.setState({ cursusRows: cursus });

    }

    removeCursus(e) {
        var array = this.state.cursusRows; // make a separate copy of the array
        var index = array.indexOf(e)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ cursusRows: array });
        }
    }
    render() {
        console.log(Store.getState()?.workplace);
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>

                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>Cursus professionnel</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => this.postSocialMedias()}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                {this.state.is_loaded && (

                                    <Col >
                                        <Card style={{ margin: 0 }}>
                                            <h3>Cursus professionnel</h3>
                                            <div className="monSite-cursusItem">
                                                <div>

                                                    <Row>
                                                        <Col>
                                                            <Row>

                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Type du cursus *
                                                                        </Label>
                                                                        <InputSelect
                                                                            value={this.state.cursusRow.cursusType && { value: this.state.cursusRow.cursusType, label: this.state.cursusRow.cursusType }}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    cursusRow: {
                                                                                        ...this.state.cursusRow,
                                                                                        cursusType: e.label
                                                                                    }
                                                                                })
                                                                            }}
                                                                            options={[{ value: "Formation", label: "Formation" }, { value: "Expérience", label: "Expérience" }, { value: "Travaux et publications", label: "Travaux et publications" }, { value: "Prix et distinctions", label: "Prix et distinctions" }]}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Nom du cursus *
                                                                        </Label>
                                                                        <Input
                                                                            isClearable
                                                                            value={this.state.cursusRow.label}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    cursusRow: {
                                                                                        ...this.state.cursusRow,
                                                                                        label: e.target.value
                                                                                    }
                                                                                })
                                                                            }}
                                                                            placeHolder='Votre nom du cursus ici ...'
                                                                        />
                                                                    </FormGroup>
                                                                </Col>


                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            De *
                                                                        </Label>
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            calendarMainColor="primary"
                                                                            placeholder="La date ici ..."
                                                                            dateFormat='YYYY-MM-DD'
                                                                            value={this.state.cursusRow.startDate ? moment(this.state.cursusRow.startDate).format('YYYY-MM-DD') : ""}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    cursusRow: {
                                                                                        ...this.state.cursusRow,
                                                                                        startDate: e
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            A
                                                                        </Label>
                                                                        <DatePicker
                                                                            showYearDropdown
                                                                            disabled={this.state.cursusRow.tillNow}
                                                                            calendarMainColor="primary"
                                                                            placeholder="La date ici ..."
                                                                            dateFormat='YYYY-MM-DD'
                                                                            value={this.state.cursusRow.endDate ? moment(this.state.cursusRow.endDate).format('YYYY-MM-DD') : ""}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    cursusRow: {
                                                                                        ...this.state.cursusRow,
                                                                                        endDate: e
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                        <Label
                                                                            style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
                                                                        // for={item.socialMediaType}
                                                                        >
                                                                            <CheckBox
                                                                                // id={item.socialMediaType}
                                                                                value={this.state.cursusRow.tillNow}
                                                                                checked={this.state.cursusRow.tillNow}
                                                                                onChange={() => {
                                                                                    this.setState({
                                                                                        cursusRow: {
                                                                                            ...this.state.cursusRow,
                                                                                            endDate: "",
                                                                                            tillNow: !this.state.cursusRow.tillNow
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            >
                                                                            </CheckBox>  <span>Jusqu'à aujourd'hui</span>
                                                                        </Label>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>
                                                                    Description du cursus
                                                                </Label>
                                                                <Input
                                                                    isClearable
                                                                    type="textarea"
                                                                    value={this.state.cursusRow.description}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            cursusRow: {
                                                                                ...this.state.cursusRow,
                                                                                description: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                    rows="7"
                                                                    placeHolder='Votre description du cursus ici ...'
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                        <div style={{ margin: 'auto 0' }}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    if (this.state.cursusRow.label !== "" && this.state.cursusRow.cursusType !== "" && this.state.cursusRow.startDate !== "") {
                                                                        this.getCursusRow();
                                                                    } else {
                                                                        notify({ type: "info", msg: "Veuillez remplir les champs requis (*)", delay: 4000 })
                                                                    }
                                                                }}
                                                                color="primary">
                                                                <AiOutlinePlus size={20} ></AiOutlinePlus>
                                                            </IconButton>
                                                        </div>
                                                    </Row>
                                                </div>

                                                <hr />

                                                {this.state.cursusRows.map((cursusRow, index) => {
                                                    return (

                                                        <div>
                                                            <Row key={index}>
                                                                <Col>
                                                                    <Row>

                                                                        <Col>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Type du cursus
                                                                                </Label>
                                                                                <InputSelect
                                                                                    value={cursusRow.cursusType && { value: cursusRow.cursusType, label: cursusRow.cursusType }}
                                                                                    onChange={(e) => {
                                                                                        this.handleCursusChange(index, 'cursusType', e.value)
                                                                                    }}
                                                                                    options={[{ value: "Formation", label: "Formation" }, { value: "Expérience", label: "Expérience" }, { value: "Travaux et publications", label: "Travaux et publications" }, { value: "Prix et distinctions", label: "Prix et distinctions" }]}

                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Nom du cursus
                                                                                </Label>
                                                                                <Input
                                                                                    isClearable
                                                                                    value={cursusRow.label}
                                                                                    onChange={(e) => {
                                                                                        this.handleCursusChange(index, 'label', e.target.value)
                                                                                    }}
                                                                                    placeHolder='Votre nom du cursus ici ...'
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    De
                                                                                </Label>
                                                                                <DatePicker
                                                                                    showYearDropdown
                                                                                    calendarMainColor="primary"
                                                                                    dateFormat='YYYY-MM-DD'
                                                                                    value={moment(cursusRow.startDate).format('YYYY-MM-DD')}
                                                                                    onChange={(e) => {
                                                                                        this.handleCursusChange(index, 'startDate', e)
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col>
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    A
                                                                                </Label>
                                                                                <DatePicker
                                                                                    disabled={cursusRow.tillNow}
                                                                                    showYearDropdown
                                                                                    calendarMainColor="primary"
                                                                                    dateFormat='YYYY-MM-DD'
                                                                                    value={!cursusRow.endDate ? "" : moment(cursusRow.endDate).format('YYYY-MM-DD')}
                                                                                    onChange={(e) => {
                                                                                        this.handleCursusChange(index, 'endDate', e)
                                                                                    }}
                                                                                />
                                                                                <Label
                                                                                    style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
                                                                                // for={item.socialMediaType}
                                                                                >
                                                                                    <CheckBox
                                                                                        // id={item.socialMediaType}
                                                                                        value={cursusRow.tillNow}
                                                                                        checked={cursusRow.tillNow}
                                                                                        onChange={() => {
                                                                                            this.handleCursusChange(index, 'tillNow', !cursusRow.tillNow)
                                                                                        }}
                                                                                    >
                                                                                    </CheckBox>  <span>Jusqu'à aujourd'hui</span>
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>
                                                                            Description du cursus
                                                                        </Label>
                                                                        <Input
                                                                            isClearable
                                                                            type="textarea"
                                                                            value={cursusRow.description}
                                                                            onChange={(e) => {
                                                                                this.handleCursusChange(index, 'description', e.target.value)
                                                                            }}
                                                                            rows="7"
                                                                            placeHolder='Votre description du cursus ici ...'
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <div style={{ margin: 'auto 0' }}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            this.removeCursus(cursusRow)
                                                                        }}
                                                                        color="danger">
                                                                        <AiOutlineMinus size={20} ></AiOutlineMinus>
                                                                    </IconButton>
                                                                </div>
                                                            </Row>
                                                            <hr />
                                                        </div>



                                                    )
                                                })}

                                            </div>
                                        </Card>
                                    </Col>

                                )}

                            </Row>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Cursus
