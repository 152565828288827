import { Button } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import logo from '../../images/logos/logo.png'

export default class Login extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            BASE_URL: process.env.REACT_APP_API_URL,
            LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
        }
    }
    
    componentDidMount(){
    }
    render() {
        return (
            <div>
                <div className="monSite-login">
                    <div className="monSite-login__button">
                        <img src={logo} alt='logo' width="100"/>
                        <Button
                            color='primary'
                            block
                            onClick={()=>{
                                window.location.href=`https://${this.state.LOGIN_URL}/authorize?response_type=code&client_id=10056&scope=openid%20offline_access%20profile%20email&redirect_uri=${this.state.BASE_URL}/auth/token&prompt=login`
                            }}
                        >
                            Se connecter
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}
