import { FormFeedback, Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, FormGroup, IconButton, Input, InputSelect, Label, notify, Row, SpinnerLoading, EmptyData, FormText } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import SideMenu from '../../components/Monsite/SideMenu'
import Header from '../../helpers/Header/Header'
import CareServiceService from '../../services/CareServiceService'
import Store from '../../store/configStore';

class CareService extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            workplace: Store.getState()?.workplace?.workplace,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            addNewRow: false,
            errors: {},
            is_loaded: false,
            allCareServices: [],
            careServiceRows: [{
                label: "",
                detail: ""
            }],
            careServiceRow: {
                label: "",
                detail: ""
            }
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (this.state.careServiceRow.label === '') {
            formIsValid = false;
            errors["label"] = "Ce champ est obligatoire";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    getCareServiceRow = () => {
        const newRow = this.state.careServiceRows.concat(this.state.careServiceRow);
        this.setState({
            careServiceRows: newRow,
            careServiceRow: {
                label: "",
                detail: ""
            }
        })
    }

    componentDidMount() {
        this.getCareservices();
    }



    getCareservices = async () => {
        try {
            let response = await CareServiceService.getCareService();
            let allCareservicesResponse = await CareServiceService.getAllCareServices();
            if (response.data.length !== 0) {
                this.setState({
                    addNewRow: true
                })
            }
            this.setState({
                careServiceRows: response.data,
                is_loaded: true
            })
        } catch (error) {

        }
    }

    postCareservices = async () => {
        this.setState({ is_loaded: false })
        try {
            await CareServiceService.addCareService(this.state.careServiceRows)
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))
        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }

    handleCareServiceChange(index, field, value) {
        let videos = this.state.careServiceRows;
        videos[index] = { ...videos[index] };
        videos[index][field] = value;
        this.setState({ careServiceRows: videos });

    }

    removeCareService(e) {
        var array = this.state.careServiceRows; // make a separate copy of the array
        var index = array.indexOf(e)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ careServiceRows: array });
        }
    }

    render() {
        const { careServiceRows } = this.state;
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <>

                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>{this.state.workplace === "cabinet" ? "Présentation du cabinet" : (this.state.workplace === "centre de santé" ? "Présentation du centre de santé" :(this.state.workplace === "clinique" ? "Présentation de la clinique" : "Présentation du docteur"))}</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => this.postCareservices()}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ margin: 0 }}>
                                        <h3>Soins et actes</h3>
                                        <p>Ajoutez vos soins et actes dans cette rubrique</p>

                                        {this.state.addNewRow && (
                                            <div>
                                                <div>

                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Nom du soin ou acte</Label>
                                                                <Input
                                                                    invalid={this.state.errors["label"]}
                                                                    value={this.state.careServiceRow.label}
                                                                    placeHolder="Nom ici .."
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            careServiceRow: {
                                                                                ...this.state.careServiceRow,
                                                                                label: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <FormFeedback invalid>{this.state.errors["label"]}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Détail du soin ou acte</Label>
                                                                <Input
                                                                    invalid={this.state.errors["detail"]}
                                                                    value={this.state.careServiceRow.detail}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            careServiceRow: {
                                                                                ...this.state.careServiceRow,
                                                                                detail: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                    placeHolder="Détails ici .."
                                                                />
                                                                <FormText color="muted">Le détail est limité sur 255 caractères</FormText>
                                                            </FormGroup>
                                                        </Col>
                                                        <div style={{ marginTop: 'auto', position: "relative", top: "-18px" }}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    if (this.handleValidation()) {
                                                                        this.getCareServiceRow();
                                                                    }
                                                                }}
                                                                color="primary">
                                                                <AiOutlinePlus size={20} ></AiOutlinePlus>
                                                            </IconButton>
                                                        </div>
                                                    </Row>
                                                </div>

                                                {careServiceRows && careServiceRows.map((careServiceRow, index) => {
                                                    return (

                                                        <div key={index}>

                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Nom du soin ou acte</Label>
                                                                        <Input
                                                                            value={careServiceRow.label}
                                                                            onChange={(e) => {
                                                                                this.handleCareServiceChange(index, 'label', e.target.value)
                                                                                // this.setState({
                                                                                //     careServiceRow: {
                                                                                //         ...this.state.careServiceRow,
                                                                                //         label: { value: e.value, label: e.label }
                                                                                //     }
                                                                                // })
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Détail du soin ou acte</Label>
                                                                        <Input
                                                                            value={careServiceRow.detail}
                                                                            onChange={(e) => {
                                                                                this.handleCareServiceChange(index, 'detail', e.target.value)
                                                                                //     this.setState({
                                                                                //         careServiceRow: {
                                                                                //             ...this.state.careServiceRow,
                                                                                //             detail: e.target.value
                                                                                //         }
                                                                                //     })
                                                                            }}
                                                                            placeHolder="Détails ici .."
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <div style={{ marginTop: 'auto' }}>
                                                                    <IconButton
                                                                        onClick={(e) => {
                                                                            this.removeCareService(careServiceRow)
                                                                        }}
                                                                        color="danger">
                                                                        <AiOutlineMinus size={20} ></AiOutlineMinus>
                                                                    </IconButton>
                                                                </div>
                                                            </Row>

                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        )}
                                        {careServiceRows.length === 0 && (
                                            <div className="monSite-stepsHeader">
                                                <EmptyData title="Aucun soin et acte renseigné" desc="Pour ajouter un nouveau soin et acte, utilisez le bouton ci-dessous">
                                                    {!this.state.addNewRow && (
                                                        <Button
                                                            color="info"
                                                            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                                            onClick={() => {
                                                                return this.setState({
                                                                    addNewRow: true
                                                                }, () => careServiceRows.length !== 0)
                                                            }
                                                            }
                                                        >
                                                            <AiOutlinePlus />Nouveau soin et acte
                                                        </Button>
                                                    )}
                                                </EmptyData>
                                            </div>
                                        )}
                                    </Card>
                                </Col>
                            </Row>

                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default CareService
