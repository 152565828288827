import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, Row } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import chezledocteur from '../../images/icons/chezledocteur.svg'
import arrowRight from '../../images/icons/arrowRight.svg'
import SideMenu from '../../components/Monsite/SideMenu'
import Header from '../../helpers/Header/Header'

class ChezLeDocteur extends Component {
    render() {
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    <div className="monSite-breadcrumb">
                        <Breadcrumb>
                            <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                            <BreadcrumbBody>
                                <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                <BreadcrumbItem active>Prendre RDV</BreadcrumbItem>
                            </BreadcrumbBody>
                        </Breadcrumb>
                        <div>
                            <Button
                                style={{ marginLeft: "0.5rem" }}
                                outline
                                color="primary"
                            // className="btn-outline"
                            // onClick={() => selectModal()}
                            >
                                Visualiser
                            </Button>
                            <Button
                                style={{ marginLeft: "0.5rem" }}
                                color="primary"
                            // className="btn-outline"
                            // onClick={() => selectModal()}
                            >
                                Enregistrer
                            </Button>
                        </div>
                    </div>
                    {/* <Container> */}
                    <Row>
                        <Col xs="3">
                            <Card style={{ margin: 0 }}>
                                <SideMenu />
                            </Card>
                        </Col>
                        <Col >
                            <Card style={{ margin: 0 }}>
                                <h3>Prise de rendez-vous</h3>
                                <div className="monSite-stepsHeader">
                                    <div className="monSite-stepsHeaderIcon">
                                        <img src={chezledocteur} />
                                    </div>
                                    <div className="monSite-stepsTitle">
                                        <h3>Pour activer la prise de rendez-vous, merci de s’inscrire sur ChezLeDocteur</h3>
                                    </div>
                                    <div className="monSite-stepsDescription">
                                        <p>Vous bénéficierez de plusieurs avantages en souscrivant à notre service de prise de rendez-vous</p>
                                    </div>
                                    <Button
                                        color="primary"
                                        outline
                                        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                    >
                                        Profiter dés maintenant
                                        <img src={arrowRight} style={{ marginLeft: "1rem" }} />
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}

export default ChezLeDocteur
