import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import UserManual from './components/Monsite/Settings/UserManual/UserManual';
import ToastComponent from './components/Monsite/ToastComponent';
import Header from './helpers/Header/Header';
import AccessDetail from './pages/Monsite/AccessDetail';
import ChezLeDocteur from './pages/Monsite/ChezLeDocteur';
import Cursus from './pages/Monsite/Cursus';
import Menu from './pages/Monsite/Menu';
import Heading from './pages/Monsite/Heading';
import Presentation from './pages/Monsite/Presentation';
import Slide from './pages/Monsite/Slide';
import SocialMedia from './pages/Monsite/SocialMedia';
import Steps from './pages/Monsite/Steps';
import SubDomain from './pages/Monsite/SubDomain';
import Transformation from './pages/Monsite/Transformation';
import VisualParams from './pages/Monsite/VisualParams';
import { Toast } from '@laazyry/sobrus-design-system';
import CareService from './pages/Monsite/CareService';
import HealthCareEstablishmentImage from './pages/Monsite/HealthCareEstablishmentImage';
import Video from './pages/Monsite/Video';
import Login from './components/Monsite/Login';
import { ProtectedRoute } from './protectedRoute';
import 'react-whatsapp-widget/dist/index.css'
import Dashboard from './pages/Monsite/Dashboard';
import NotFoundPage from './pages/NotFoundPage';
import ErrorPage from './pages/ErrorPage'
import Banner from './pages/Monsite/Banner';

function App() {
  
  return (
    <div>
      <Toast />
      <BrowserRouter>
      
        <Switch>
          <Redirect from="/" exact to="/dashboard" />
          <Route path="/auth/login" exact component={Login} />
          <ProtectedRoute path="/dashboard" exact component={Dashboard} />
          <ProtectedRoute path="/settings" exact component={Steps} />
          <ProtectedRoute path="/settings/siteContent/cursus" exact component={Cursus} />
          <ProtectedRoute path="/settings/siteContent/presentations" exact component={Presentation} />
          <ProtectedRoute path="/settings/siteContent/careservices" exact component={CareService} />
          <ProtectedRoute path="/settings/siteContent/accessDetails" exact component={AccessDetail} />
          <ProtectedRoute path="/settings/layoutHeadings/headings" exact component={Heading} />
          <ProtectedRoute path="/settings/customization/visualParams" exact component={VisualParams} />
          <ProtectedRoute path="/settings/customization/banner" exact component={Banner} />
          <ProtectedRoute path="/settings/customization/menus" exact component={Menu} />
          <ProtectedRoute path="/settings/customization/subdomains" exact component={SubDomain} />
          <ProtectedRoute path="/settings/siteContent/slides" exact component={Slide} />
          <ProtectedRoute path="/settings/siteContent/transformations" exact component={Transformation} />
          <ProtectedRoute path="/settings/siteContent/socialMedias" exact component={SocialMedia} />
          <ProtectedRoute path="/settings/siteContent/chezledocteur" exact component={ChezLeDocteur} />
          <ProtectedRoute path="/settings/siteContent/healthcareEstablishmentImages" exact component={HealthCareEstablishmentImage} />
          <ProtectedRoute path="/settings/siteContent/videos" exact component={Video} />
          <Route path="/auth/failed" exact component={ErrorPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>

    </div>
  );
}

export default App;
