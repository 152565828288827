import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class MenuService {
  getMenuItems() {
    return authAxios.get(`/menuItems`, {
      withCredentials: true,
    });
  }
  addMenuItems(data){
    return authAxios.post(`/menuItems`, data , {
      withCredentials: true,
    });
  }
  updateMenuItems(data){
    return authAxios.post(`/menuItems?_method=PATCH`, data , {
      withCredentials: true,
    })
  }
}
export default new MenuService();