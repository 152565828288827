import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class CareServiceService {
  getCareService() {
    return authAxios.get(`/careServices`, {
      withCredentials: true,
    });
  }
  addCareService(data) {
    return authAxios.post(`/careServices`, data, {
      withCredentials: true,
    });
  }
  getAllCareServices() {
    return authAxios.get(`/careServices/list`, {
      withCredentials: true,
    });
  }
}

export default new CareServiceService
