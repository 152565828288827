import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class CursusService {
  getCursus() {
    return authAxios.get(`/cursus`, {
      withCredentials: true,
    });
  }
  addCursus(data){
    return authAxios.post(`/cursus`, data , {
      withCredentials: true,
    });
  }
}
export default new CursusService();