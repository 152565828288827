import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class SlideService {
  getSlides() {
    return authAxios.get(`/slides`, {
      withCredentials: true,
    });
  }
  addSlides(data){
    return authAxios.post(`/slides`, data , {
      withCredentials: true,
    });
  }
  updateSlides(data, id){
    return authAxios.post(`/slides/${id}?_method=PATCH`, data , {
      withCredentials: true,
    })
  }
}
export default new SlideService();