import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class PresentationService {
  getPresentation() {
    return authAxios.get(`/presentations`, {
      withCredentials: true,
    });
  }
  addPresentation(data){
    return authAxios.post(`/presentations`, data , {
      withCredentials: true,
    });
  }
  updatePresentation(data, id){
    return authAxios.post(`/presentations/${id}?_method=PATCH`, data , {
      withCredentials: true,
    })
  }
}
export default new PresentationService();