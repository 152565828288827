import React from "react";
import { FiUser } from "react-icons/fi";
import "./styles.css";

const NavProfileLink = ({ children, ...rest }) => {
    return (
        <a className={`dropdown-item-link ${rest.className || ""}`} href={rest.href} {...rest} >
            <span className="dropdown-item-icon-container">
                <FiUser color="#55aced" size={22} />
            </span>
            <span className="dropdown-item-text">Profil</span>
        </a>
    )
}

export default NavProfileLink ;