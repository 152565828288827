import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, CheckBox, Col, FormGroup, FormText, IconButton, Input, Label, notify, Row, SpinnerLoading, SwitchButton } from '@laazyry/sobrus-design-system'
import TimePicker from '../../components/Monsite/TimePicker'
import React, { Component } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { RiQuestionFill } from 'react-icons/ri'
import ReactTooltip from 'react-tooltip'
import SideMenu from '../../components/Monsite/SideMenu'
import AccessDetailService from '../../services/AccessDetailService'
import localisation from '../../images/localisation.png'
import Header from '../../helpers/Header/Header'
import moment from 'moment'
import Store from '../../store/configStore';
import VisualParamService from '../../services/VisualParamService';

class AccessDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            selectedDate: "00:00",
            showLocalisation: false,
            workplace: "",
            is_loaded: false,
            doctorInfos: {},
            emailVisibility: false,
            phoneVisibility: false,
            openingHours: [],
            days: [
                {
                    dayOfWeek: "Lundi",
                    status: false,
                    startOne: "00:00",
                    endOne: "00:00",
                    startTwo: "00:00",
                    afternoon: false,
                    endTwo: "00:00"
                },
                {
                    dayOfWeek: "Mardi",
                    status: false,
                    startOne: "00:00",
                    endOne: "00:00",
                    startTwo: "00:00",
                    afternoon: false,
                    endTwo: "00:00"
                },
                {
                    dayOfWeek: "Mercredi",
                    status: false,
                    startOne: "00:00",
                    endOne: "00:00",
                    startTwo: "00:00",
                    afternoon: false,
                    endTwo: "00:00"
                },
                {
                    dayOfWeek: "Jeudi",
                    status: false,
                    startOne: "00:00",
                    endOne: "00:00",
                    startTwo: "00:00",
                    afternoon: false,
                    endTwo: "00:00"
                },
                {
                    dayOfWeek: "Vendredi",
                    status: false,
                    startOne: "00:00",
                    endOne: "00:00",
                    startTwo: "00:00",
                    afternoon: false,
                    endTwo: "00:00"
                },
                {
                    dayOfWeek: "Samedi",
                    status: false,
                    startOne: "00:00",
                    endOne: "00:00",
                    startTwo: "00:00",
                    afternoon: false,
                    endTwo: "00:00"
                },
                {
                    dayOfWeek: "Dimanche",
                    status: false,
                    startOne: "00:00",
                    endOne: "00:00",
                    startTwo: "00:00",
                    afternoon: false,
                    endTwo: "00:00"
                },
            ]
        }
    }
    handleChange(e) {
        let days = [...this.state.days];
        let day = { ...days[e] };
        day.status = !day.status;
        days[e] = day;
        this.setState({ days });
    }

    componentDidMount() {
        this.getDoctorData()
        this.getVisualParams()
        console.log(this.state.emailVisibility)
        console.log(this.state.phoneVisibility)
    }

    getVisualParams = async () => {
        try {
            let response = await VisualParamService.getApplicationParams();
            if (response.data !== "") {
                this.setState({
                    workplace: response?.data?.workplace,
                })
                Store.dispatch({ type: 'SET_WORKPLACE', value: response?.data?.workplace });
            }
        } catch (error) {
            console.error('applicationParams', error)
        }
    }
    

    getDoctorData = async () => {
        try {
            let doctorInfosResponse = await AccessDetailService.getDoctorsInfo();
            this.setState({
                emailVisibility: doctorInfosResponse.data.emailVisibility,
                phoneVisibility: doctorInfosResponse.data.phoneVisibility,
            }
            )
            let doctorOpeningHoursResponse = await AccessDetailService.getOpeningHours();
            if (doctorOpeningHoursResponse.data.length !== 0) {
                doctorOpeningHoursResponse.data.forEach((day, index) => {
                    this.handleOpeningHoursChange(index, 'dayOfWeek', day.dayOfWeek)
                    this.handleOpeningHoursChange(index, 'status', day.status)
                    this.handleOpeningHoursChange(index, 'startOne', day.startOne)
                    this.handleOpeningHoursChange(index, 'endOne', day.endOne)
                    this.handleOpeningHoursChange(index, 'startTwo', day.startTwo)
                    this.handleOpeningHoursChange(index, 'endTwo', day.endTwo)
                })
            }
            this.setState({
                doctorInfos: doctorInfosResponse.data,
                is_loaded: true
            })
        } catch (error) {

        }
    }

    postDoctorData = () => {
        if(this.state.workplace === 'à domicile'){
            this.setState({
                doctorInfos:{
                    ...this.state.doctorInfos,
                    address: undefined,
                    latitude: undefined,
                    longitude: undefined,
                    emailVisibility: this.state.emailVisibility,
                    phoneVisibility: this.state.phoneVisibility, 
                }
            }, async () => {
                try {
                    await AccessDetailService.addDoctorsInfo(this.state.doctorInfos);
                    await AccessDetailService.addOpeningHours(this.state.days);
                    this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))
                } catch (error) {
                    this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
                }
            })
        }else{
            this.setState({
                is_loaded: false,
                doctorInfos: {
                    ...this.state.doctorInfos,
                    emailVisibility: this.state.emailVisibility,
                    phoneVisibility: this.state.phoneVisibility, 
                }
            }, async () => {
                try {
                    await AccessDetailService.addDoctorsInfo(this.state.doctorInfos);
                    await AccessDetailService.addOpeningHours(this.state.days);
                    this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))
                } catch (error) {
                    this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
                }
            })
        }
        
    }

    handleOpeningHoursChange(index, field, value) {
        let openingHours = this.state.days;
        openingHours[index] = { ...openingHours[index] };
        openingHours[index][field] = value;
        this.setState({ days: openingHours });
    }

    render() {
        const { days } = this.state;
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}

                    {this.state.is_loaded && (
                        <div>
                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>Détails d’accès</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => this.postDoctorData()}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ margin: 0 }}>


                                        <Row>
                                            <Col xs="4">
                                                <div className="monSite-logoParams__title">
                                                    <h3>Information générale du site web</h3>
                                                </div>
                                                <div className="monSite-logoParams__description">
                                                    <p>Ces informations sont exploitables au niveau du site web</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Row>
                                                        <Col>
                                                            <Label>
                                                                Email
                                                            </Label>
                                                            <Input
                                                                value={this.state.doctorInfos.email}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        doctorInfos: {
                                                                            ...this.state.doctorInfos,
                                                                            email: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                isClearable
                                                                type="text"
                                                                placeHolder="Votre email ici …"
                                                            ></Input>
                                                            <Label
                                                                style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
                                                            // for={item.socialMediaType}
                                                            >
                                                                <CheckBox
                                                                    // id={item.socialMediaType}
                                                                    value={this.state.emailVisibility}
                                                                    checked={!this.state.emailVisibility}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            emailVisibility: !this.state.emailVisibility
                                                                        })
                                                                    }}
                                                                >
                                                                </CheckBox>  <span>Ne pas afficher sur le site</span>
                                                            </Label>
                                                        </Col>
                                                        <Col>
                                                            <Label>
                                                                Numéro de téléphone
                                                            </Label>
                                                            <Input
                                                                value={this.state.doctorInfos.phone}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        doctorInfos: {
                                                                            ...this.state.doctorInfos,
                                                                            phone: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                isClearable
                                                                type="text"
                                                                placeHolder="Votre numéro de téléphone …"
                                                            ></Input>
                                                            <Label
                                                                style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
                                                            // for={item.socialMediaType}
                                                            >
                                                                <CheckBox
                                                                    // id={item.socialMediaType}
                                                                    value={this.state.phoneVisibility}
                                                                    checked={!this.state.phoneVisibility}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            phoneVisibility: !this.state.doctorInfos.phoneVisibility
                                                                        })
                                                                    }}
                                                                >
                                                                </CheckBox>  <span>Ne pas afficher sur le site</span>
                                                            </Label>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                {this.state.workplace !== "à domicile" && (
                                                    <FormGroup>
                                                    <Row>
                                                        <Col>
                                                            <Label>
                                                                Adresse
                                                            </Label>
                                                            <Input
                                                                value={this.state.doctorInfos.address}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        doctorInfos: {
                                                                            ...this.state.doctorInfos,
                                                                            address: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                isClearable
                                                                type="textarea"
                                                                rows="5"
                                                                placeHolder="Votre adresse ici …"
                                                            ></Input>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                )}
                                                {this.state.workplace !== "à domicile" && (
                                                <FormGroup>

                                                    <Row>
                                                        <Col>
                                                            {this.state.showLocalisation && (
                                                                <div style={{ zIndex: "9999", position: "absolute", top: "50%", right: "-50%" }}>
                                                                    <img src={localisation} width="600" />
                                                                </div>
                                                            )}
                                                            <div style={{ display: "flex", alignItems: "end", justifyContent: "space-between" }}>
                                                                <Label>
                                                                    Latitude
                                                                </Label>
                                                                <RiQuestionFill style={{ cursor: "pointer" }} color="rgba(0,0,0,.07)" size='27' onClick={() => this.setState({ showLocalisation: !this.state.showLocalisation })} />
                                                            </div>
                                                            <Input
                                                                value={this.state.doctorInfos.latitude}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        doctorInfos: {
                                                                            ...this.state.doctorInfos,
                                                                            latitude: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                isClearable
                                                                type="text"
                                                                placeHolder="Votre latitude ici …"
                                                            ></Input>
                                                        </Col>
                                                        <Col>
                                                            <div style={{ display: "flex", alignItems: "end", justifyContent: "space-between" }}>
                                                                <Label>
                                                                    Longitude
                                                                </Label>
                                                                <RiQuestionFill style={{ cursor: "pointer" }} color="rgba(0,0,0,.07)" size='27' onClick={() => this.setState({ showLocalisation: !this.state.showLocalisation })} />
                                                            </div>
                                                            <Input
                                                                value={this.state.doctorInfos.longitude}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        doctorInfos: {
                                                                            ...this.state.doctorInfos,
                                                                            longitude: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                isClearable
                                                                type="text"
                                                                placeHolder="Votre longitude ici …"
                                                            ></Input>
                                                        </Col>
                                                    </Row>


                                                </FormGroup>
                                                )}
                                                <FormGroup>
                                                    <Row>
                                                        <Col>
                                                            <Label>
                                                                Présentation du {this.state.workplace === 'à domicile' ? 'docteur' : this.state.workplace}
                                                            </Label>
                                                            <Input
                                                                value={this.state.doctorInfos.description}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        doctorInfos: {
                                                                            ...this.state.doctorInfos,
                                                                            description: e.target.value
                                                                        }
                                                                    })
                                                                }}
                                                                isClearable
                                                                type="textarea"
                                                                rows="5"
                                                                placeHolder="Votre présentation ici …"
                                                            ></Input>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <hr />
                                        <Row>
                                            <Col xs="4">
                                                <div className="monSite-logoParams__title">
                                                    <h3>Horaires de travail</h3>
                                                </div>
                                                <div className="monSite-logoParams__description">
                                                    <p>Ces informations sont exploitables au niveau du site web</p>
                                                </div>
                                            </Col>

                                            <Col>
                                                {days.map((day, index) => {
                                                    return (
                                                        <div style={{ marginBottom: "1rem" }}>
                                                            <Row key={index}>
                                                                <Col xs="2">
                                                                    {day.dayOfWeek}
                                                                </Col>
                                                                <Col xs="2">
                                                                    <div
                                                                        style={{ marginLeft: "1rem" }}>
                                                                        <SwitchButton
                                                                            onChange={() => {
                                                                                this.handleChange(index)
                                                                            }}
                                                                            checked={day.status}></SwitchButton>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    {!day.status ?
                                                                        <p style={{ color: "#F05A29" }}>Fermé</p>
                                                                        : <p style={{ color: "#1BBAAC" }}>Ouvert</p>}
                                                                </Col>
                                                            </Row>
                                                            {day.status && (
                                                                <div>
                                                                    <Row style={{ marginBottom: '.5rem' }}>
                                                                        <Col xs="3">
                                                                            <TimePicker
                                                                                value={day.startOne}
                                                                                onChange={(e)=>{
                                                                                    this.handleOpeningHoursChange(index, 'startOne', e)
                                                                                }}
                                                                            />
                                                                            {/* <TimePicker 
                                                                                value={day.startOne} onChange={(e) => {
                                                                                // console.log(e)
                                                                                this.handleOpeningHoursChange(index, 'startOne', Date.parse(e))
                                                                            }}></TimePicker> */}
                                                                        </Col>
                                                                        <Col xs="3">
                                                                            <TimePicker value={day.endOne} onChange={(e) => this.handleOpeningHoursChange(index, 'endOne', e)}></TimePicker>
                                                                        </Col>
                                                                        {day?.afternoon === false && (
                                                                            <div style={{ margin: 'auto 0' }}>
                                                                                <IconButton
                                                                                    style={{ margin: 0, lineHeight: 1 }}

                                                                                    onClick={() => {
                                                                                        console.log(day.afternoon)
                                                                                        this.handleOpeningHoursChange(index, 'afternoon', true)
                                                                                    }}
                                                                                    color="primary">
                                                                                    <AiOutlinePlus size={20} ></AiOutlinePlus>

                                                                                </IconButton>
                                                                            </div>
                                                                        )}

                                                                    </Row>
                                                                    {(day.afternoon || day.startTwo !== "00:00") && (
                                                                        <Row>
                                                                            <Col xs="3">
                                                                                <TimePicker value={day.startTwo} onChange={(e) => this.handleOpeningHoursChange(index, 'startTwo', e)}></TimePicker>
                                                                            </Col>
                                                                            <Col xs="3">
                                                                                <TimePicker value={day.endTwo} onChange={(e) => this.handleOpeningHoursChange(index, 'endTwo', e)}></TimePicker>
                                                                            </Col>
                                                                            <div style={{ margin: 'auto 0' }}>
                                                                                <IconButton
                                                                                    style={{ margin: 0, lineHeight: 1 }}
                                                                                    onClick={() => {
                                                                                        this.handleOpeningHoursChange(index, 'afternoon', false)
                                                                                        this.handleOpeningHoursChange(index, 'startTwo', "00:00")
                                                                                        this.handleOpeningHoursChange(index, 'endTwo', "00:00")
                                                                                    }}
                                                                                    color="danger">
                                                                                    <AiOutlineMinus size={20} ></AiOutlineMinus>
                                                                                </IconButton>
                                                                            </div>
                                                                        </Row>
                                                                    )}
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                        </div>
                    )
                    }

                </div>
            </div>
        )
    }
}

export default AccessDetail
