import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class HeadingService {
  getHeadings() {
    return authAxios.get(`/headings`, {
      withCredentials: true,
    });
  }
  addHeadings(data){
    return authAxios.post(`/headings`, data , {
      withCredentials: true,
    });
  }
  updateHeadings(data){
    return authAxios.post(`/headings?_method=PATCH`, data , {
      withCredentials: true,
    })
  }
}
export default new HeadingService();