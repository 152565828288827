import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class VisualParamService {
  getApplicationParams() {
    return authAxios.get(`/applicationParams`, {
      withCredentials: true,
    });
  }
  addApplicationParams(data){
    return authAxios.post(`/applicationParams`, data , {
      withCredentials: true,
    });
  }
  addApplicationParamsBanner(data){
    return authAxios.post(`/applicationParams/banner?_method=PATCH`, data , {
      withCredentials: true,
    });
  }
  updateApplicationParams(data){
    return authAxios.post(`/applicationParams?_method=PATCH`, data , {
      withCredentials: true,
    })
  }
  checkingSubdomain(data){
    return authAxios.post(`/applicationParams/subdomains`, data , {
      withCredentials: true,
    })
  }
  addsubdomain(data){
    return authAxios.post(`/applicationParams/addSubdomain`, data , {
      withCredentials: true,
    })
  }
}
export default new VisualParamService();