import React from 'react'
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { TimePicker as DefaultTimePicker} from '@laazyry/sobrus-design-system'

momentTimeZone.tz.setDefault("Africa/Casablanca");

function TimePicker({value, onChange, ...props}) {
    return (
        <DefaultTimePicker
        value={moment(moment().format("YYYY-MM-DD") + ' ' + value)}
        onChange={(time) => {
            onChange(time.format("HH:mm"))
        }}
        {...props}
        />
    )
}

export default TimePicker

