import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, Container, Row, StepButton } from '@laazyry/sobrus-design-system';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import stepsHeaderIcon from '../../images/icons/stepsHeaderIcon.svg'
import arrowForward from '../../images/icons/arrowForward.svg'
import SideMenu from '../../components/Monsite/SideMenu';
import ModalComponent from '../../components/Monsite/ModalComponent';
import DragDrop from '../../components/Monsite/DragDrop';
import Header from '../../helpers/Header/Header';
import Store from '../../store/configStore';

class Steps extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            steps: [
                {
                    id: 1,
                    path: '/settings/customization/visualParams',
                    title: "Personnalisez votre site web",
                    description: "Adapter votre charte graphique (Logo, Couleur, …)",
                },
                {
                    id: 2,
                    path: '/settings/customization/subdomains',
                    title: "Choisir le nom de domaine",
                    description: "Choisissez votre nom de domaine pour vous démarquer",
                },
                {
                    id: 3,
                    path: '/settings/customization/menus',
                    title: "Personnalisez votre menu",
                    description: "Adapter le classement de votre menu",
                },
                {
                    id: 4,
                    path: '/settings/siteContent/accessDetails',
                    title: "Ajouter les informations de votre cabinet",
                    description: "Ajouter le détails d’acces (Map, Email, Téléphone, …)",
                },
                {
                    id: 5,
                    path: '/settings/siteContent/slides',
                    title: "Ajouter slider",
                    description: "Ajouter des photos avec leurs titres",
                },
                {
                    id: 6,
                    path: '/settings/siteContent/careservices',
                    title: "Ajoutez vos actes et soins",
                    description: "Choisissez les actes et soins pour plus de visibilité",
                }
            ]
        }
    }

    modalBody = () => {
        return (
            <div>
                Hello world
            </div>
        )
    }
    modalFooter = () => {
        return (<Button color="primary"> Enregistrer</Button>)
    }
    render() {
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    <div className="monSite-breadcrumb">
                        <Breadcrumb>
                            <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                            <BreadcrumbBody>
                                <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                <BreadcrumbItem active>Guide d'utilisation</BreadcrumbItem>
                            </BreadcrumbBody>
                        </Breadcrumb>
                        <div>
                            {this.state.subdomain !== null && (
                                <Button
                                    style={{ marginLeft: "0.5rem" }}
                                    outline
                                    color="primary"
                                    // className="btn-outline"
                                    onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                >
                                    Visualiser
                                </Button>
                            )}
                        </div>
                    </div>
                    {/* <Container> */}
                    <Row>
                        <Col xs="3">
                            <Card style={{ margin: 0 }}>
                                <SideMenu />
                            </Card>
                        </Col>
                        <Col >
                            <Card style={{ margin: 0 }}>
                                {/* <ModalComponent header={"title"} body={this.modalBody()} footer={this.modalFooter()}/> */}
                                <div className="monSite-stepsHeader">
                                    <div className="monSite-stepsHeaderIcon">
                                        <img src={stepsHeaderIcon} />
                                    </div>
                                    <div className="monSite-stepsTitle">
                                        <h3>Dans cette section, nous allons vous aider à créer votre site étape par étape</h3>
                                    </div>
                                    <div className="monSite-stepsDescription">
                                        <p>Suivez ces étapes pour commencer à créer votre site web</p>
                                    </div>
                                </div>
                                <div className="monSite-stepsBody">
                                    {this.state.steps.map((item, index) => {
                                        return (
                                            <Col xs="6">
                                                <StepButton
                                                    onClick={() => { this.props.history.push(item.path) }}
                                                    title={item.title}
                                                    desc={item.description}
                                                    // isValid={true}
                                                    stepNum={index + 1}
                                                />
                                                {/* <div className="monSite-stepItem">
                                                        <div className="monSite-stepNumber">
                                                            {index + 1}
                                                        </div>
                                                        <div className="monSite-stepBody">
                                                            <h3>Personnalisez votre site web</h3>
                                                            <p>Adapter votre charte graphique (Logo, Couleur, …)</p>
                                                        </div>
                                                        <div className="monSite-stepButton" onClick={() => { this.props.history.push(item.path) }}>
                                                            <img src={arrowForward} />
                                                        </div>
                                                    </div> */}
                                            </Col>
                                        )
                                    })}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {/* </Container> */}
                </div>

            </div>
        )
    }
}
export default withRouter(Steps);
