import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, FormGroup, Input, Label, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import { RiQuestionFill } from 'react-icons/ri'
import SideMenu from '../../components/Monsite/SideMenu'
import banner from '../../images/banner.png'
import Header from '../../helpers/Header/Header'
import Store from '../../store/configStore';
import VisualParamService from '../../services/VisualParamService'

class Banner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            is_loaded: true,
            bannerText: "",
            showBanner: false,
            errors: {}
        }
    }

    handleChange(e) {
        let days = [...this.state.days];
        let day = { ...days[e] };
        day.status = !day.status;
        days[e] = day;
        this.setState({ days });
    }

    componentDidMount() {
        this.getBannerText();
    }

    getBannerText = async () => {
        try {
            let response = await VisualParamService.getApplicationParams();

            this.setState({
                bannerText: response.data.bannerText,
                is_loaded: true
            })
        } catch (error) {
            console.error('applicationParams', error);
            this.setState({
                is_loaded: true
            })
        }
    }


    postBannerText = async () => {
        // if (this.handleValidation()) {
            this.setState({ is_loaded: false })
            try {
                let formData = new FormData();
                    formData.append("bannerText", this.state.bannerText);
                
                await VisualParamService.addApplicationParamsBanner(formData);

                this.setState({ is_loaded: true, dataIsChanged: false }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))
            } catch (error) {
                this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
            }
        // }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (!this.state.bannerText || this.state.bannerText === "") {
            formIsValid = false;
            errors["bannerText"] = "Ce champ est requis";
        }


        this.setState({ errors: errors });
        return formIsValid;
    }


    render() {
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}

                    {this.state.is_loaded && (
                        <div>
                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Personnalisation</BreadcrumbItem>
                                        <BreadcrumbItem active>Bandeau</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => {
                                            this.postBannerText()
                                        }
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ margin: 0 }}>


                                        <Row>
                                            <Col xs="4">
                                                <div className="monSite-logoParams__title">
                                                    <h3>Bandeau du site</h3>
                                                </div>
                                                <div className="monSite-logoParams__description">
                                                    <p>Choisissez le texte au niveau du bandeau du site web</p>
                                                </div>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Row style={{position: "relative"}}>
                                                        <Col>
                                                            {this.state.showBanner && (
                                                                <div style={{ zIndex: "9999", position: "absolute", top: "50%", right: "0" }}>
                                                                    <img src={banner} width="600" />
                                                                </div>
                                                            )}
                                                            <div style={{ display: "flex", alignItems: "end", justifyContent: "space-between" }}>
                                                                <Label>
                                                                    Texte de bandeau
                                                                </Label>
                                                                <RiQuestionFill style={{ cursor: "pointer" }} color="rgba(0,0,0,.07)" size='27' onClick={() => this.setState({ showBanner: !this.state.showBanner })} />
                                                            </div>
                                                            <Input
                                                                value={this.state.bannerText}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        bannerText: e.target.value
                                                                    })
                                                                }}
                                                                isClearable
                                                                type="text"
                                                                placeHolder="Modifier le texte du message du bandeau …"
                                                            />
                                                            <small style={{ color: "#f05a29", fontSize: ".775rem" }}>{this.state.errors["bannerText"]}</small>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                        </div>
                    )
                    }

                </div>
            </div>
        )
    }
}

export default Banner
