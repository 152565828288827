import axios from "axios";

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
});

class TransformationService {
  getPictures() {
    return authAxios.get(`/transformationPictures`, {
      withCredentials: true,
    });
  }
  addPictures(data){
    return authAxios.post(`/transformationPictures`, data , {
      withCredentials: true,
    });
  }
  updatePictures(data, id){
    return authAxios.post(`/transformationPictures/${id}?_method=PATCH`, data , {
      withCredentials: true,
    })
  }
}
export default new TransformationService();