import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, CheckBox, Col, FormFeedback, FormGroup, Input, Label, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import SideMenu from '../../components/Monsite/SideMenu'
import Facebook from "../../images/icons/facebookIcon.svg"
import Twitter from "../../images/icons/twitter.svg"
import Instagram from "../../images/icons/instagram.svg"
import Linkedin from "../../images/icons/linkedin.svg"
import Youtube from "../../images/icons/youtube.svg"
import SocialMediaService from '../../services/SocialMediaService'
import ModalComponent from '../../components/Monsite/ModalComponent'
import warningIcon from "../../images/icons/warningIcon.svg";
import Header from '../../helpers/Header/Header'
import Store from '../../store/configStore';

class SocialMedia extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            showAlert: false,
            errors: {},
            is_loaded: false,
            socialMedia: [
                {
                    socialMediaType: "Facebook",
                    link: "",
                    status: true,
                    icon: Facebook
                },
                {
                    socialMediaType: "Twitter",
                    link: "",
                    status: true,
                    icon: Twitter
                },
                {
                    socialMediaType: "Instagram",
                    link: "",
                    status: true,
                    icon: Instagram
                },
                {
                    socialMediaType: "LinkedIn",
                    link: "",
                    status: true,
                    icon: Linkedin
                },
                {
                    socialMediaType: "Youtube",
                    link: "",
                    status: true,
                    icon: Youtube
                }
            ]
        }
    }

    updateStatusOfSocialMedia(e) {
        let socialMedias = this.state.socialMedia;
        let socialMedia = socialMedias[e];
        socialMedia.status = !socialMedia.status;
        socialMedias[e] = socialMedia;
        this.setState({ socialMedias });
    }

    handleSocialMediaChange(index, field, value) {

        let socialMedias = this.state.socialMedia;
        socialMedias[index] = { ...socialMedias[index] };
        socialMedias[index][field] = value;
        this.setState({ socialMedia: socialMedias });

    }
    componentDidMount() {
        this.getSocialMedias();
    }
    getSocialMedias = async () => {
        try {
            let response = await SocialMediaService.getSocialMedias();
            response.data.forEach(element => {
                this.setState(prevState => ({ socialMedia: prevState.socialMedia.map(el => (el.socialMediaType === element.socialMediaType ? { ...el, status: element.status, link: element.link } : el)) }))
            });
            // if (response.data !== "") {
            //     this.setState({
            //         socialMedia: response.data
            //     })
            // }
            this.setState({
                is_loaded: true
            })
        } catch (error) {

        }
    }
    postSocialMedias = async () => {
        this.setState({ is_loaded: false })
        let data = [];
        this.state.socialMedia.forEach(item => {
            if (item.link !== "") {
                data.push(item)
            }
        });
        try {
            await SocialMediaService.addSocialMedias(data);
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))

        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        this.state.socialMedia.forEach(item => {
            if (!item.status && item.link === "") {
                formIsValid = false;
                errors[item.socialMediaType] = "Veuillez ajouter le lien de votre compte " + item.socialMediaType;
            }
        });

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleShowAlert = (data) => {
        this.setState({
            showAlert: data
        })
    }

    alertBody = () => {
        return (
            <div>
                <Row>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                        <div className="monSite-alertIcon" >
                            <img src={warningIcon} />
                        </div>
                        <div className="monSite-stepsTitle">
                            <h4>Enregistrez les changements avant de quitter</h4>
                        </div>
                        <div className="monSite-stepsDescription">
                            <p>Merci d’enregistrer les changements opérés avant de visualiser votre site web</p>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
    alertFooter = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Button
                    color="primary"
                    onClick={() => {
                        if (this.handleValidation()) {
                            this.postSocialMedias()
                        }
                        this.setState({
                            showAlert: false
                        })

                    }}
                >
                    Enregistrer
                </Button>
                <Button
                    outline
                    color="warning"
                    onClick={() => {
                        this.setState({
                            showAlert: false,
                        })
                    }}
                >
                    Annuler
                </Button>

            </div>
        )
    }

    render() {
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {this.state.showAlert && (
                        <div className="alertModal">
                            <ModalComponent
                                className="alertModal"
                                hideHeader={true}
                                onClose={() => this.setState({ showAlert: false })}
                                isOpen={this.state.showAlert}
                                body={this.alertBody()}
                                footer={this.alertFooter()}
                            />
                        </div>
                    )}
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>

                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>Réseaux sociaux</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        color="primary"
                                        onClick={() => {
                                            if (this.handleValidation()) {
                                                this.postSocialMedias()
                                            }
                                            this.setState({
                                                showAlert: false
                                            })
                                        }}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu handleShowAlert={this.handleShowAlert} />
                                    </Card>
                                </Col>
                                {this.state.is_loaded && (

                                    <Col>
                                        <Card style={{ margin: 0 }}>
                                            <Row>
                                                <Col xs="4">
                                                    <h3>Ajoutez vos réseaux sociaux</h3>
                                                    <p>
                                                        Ces informations sont exploitables au niveau du site web
                                                    </p>
                                                </Col>
                                                <Col>
                                                    {this.state.socialMedia.map((item, index) => {
                                                        return (

                                                            <FormGroup>
                                                                <div className="monSite-socialMediaInfos">
                                                                    <div className="monSite-socialMediaInfos__icon">
                                                                        <img src={item.icon} />
                                                                    </div>
                                                                    <div className="monSite-socialMediaInfos__link">
                                                                        <Label>
                                                                            Lien {item.socialMediaType}
                                                                        </Label>
                                                                        <Input
                                                                            invalid={this.state.errors[item?.socialMediaType]}
                                                                            value={item.link}
                                                                            onChange={(e) => {
                                                                                this.handleSocialMediaChange(index, 'link', e.target.value)
                                                                                // this.setState({ dataIsChanged: true })
                                                                            }}
                                                                            type="text"
                                                                            placeHolder={`Votre lien ${item.socialMediaType} ici ...`}
                                                                        />
                                                                        <FormFeedback invalid>{this.state.errors[item.socialMediaType]}</FormFeedback>
                                                                        <FormGroup style={{ marginTop: "1rem" }}>
                                                                            <Label style={{ display: "flex", alignItems: "center" }} for={item.socialMediaType}>
                                                                                <CheckBox
                                                                                    id={item.socialMediaType}
                                                                                    value={!item.status}
                                                                                    checked={!item.status}
                                                                                    onChange={() => {
                                                                                        this.handleSocialMediaChange(index, 'status', !item.status)
                                                                                        // this.setState({ dataIsChanged: true })
                                                                                    }}>
                                                                                </CheckBox>  <span>Ne pas afficher sur le site</span>
                                                                            </Label>
                                                                            <FormFeedback valid>Sweet! that name is available</FormFeedback>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>

                                                        )
                                                    })}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>

                                )}

                            </Row>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default SocialMedia
