import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL + "/api",
    withCredentials:true
})

export const login = async (username, password) => {
    try {
        const response = await instance.post('/login_check',{username, password});
        return response;
    } catch (error) {
        throw error;
    }
}

export const refresh = async () => {
    try {
        await instance.get('/token/refresh', {withCredentials:true});
        window.location.reload();
    } catch (error) {
        if(error.response.data.message === "An authentication exception occurred.") {
            window.location.pathname = "/admin/login";
        }
    }
}
