import { FormFeedback, Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, FormGroup, IconButton, Input, InputSelect, Label, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import SideMenu from '../../components/Monsite/SideMenu'
import Header from '../../helpers/Header/Header'
import VideoService from '../../services/VideoService'
import Store from '../../store/configStore';

class Video extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            workplace: Store.getState()?.workplace?.workplace,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            errors: {},
            is_loaded: false,
            videoRows: [{
                id: "",
                name: "",
                videoPath: ""
            }],
            videoRow: {
                id: "",
                name: "",
                videoPath: ""
            }
        }
    }
    getVideoRow = () => {
        const newRow = this.state.videoRows.concat(this.state.videoRow);
        this.setState({ videoRows: newRow })
    }
    componentDidMount() {
        this.getVideos()
    }
    getVideos = async () => {
        try {
            let response = await VideoService.getVideos();
            this.setState({
                videoRows: response.data
            })
            // response.data.forEach(element => {
            //     this.setState(prevState => ({
            //         videoRows: prevState.videoRows.map(el => ({ ...el, name: element.name, videoPath: element.videoPath }))
            //     }))
            // });
            // }
            this.setState({ is_loaded: true })
        } catch (error) {

        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (this.state.videoRow.name === '') {
            formIsValid = false;
            errors["name"] = "Ce champ est obligatoire";
        }
        if (this.state.videoRow.videoPath === '') {
            formIsValid = false;
            errors["videoPath"] = "Ce champ est obligatoire";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    addVideos = async () => {
        this.setState({ is_loaded: false })
        try {
            await VideoService.addVideos(this.state.videoRows);
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 }))

        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }

    handleVideoChange(index, field, value) {
        let videos = this.state.videoRows;
        videos[index] = { ...videos[index] };
        videos[index][field] = value;
        this.setState({ videoRows: videos });

    }

    removeVideo(e) {
        var array = this.state.videoRows; // make a separate copy of the array
        var index = array.indexOf(e)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ videoRows: array });
        }
    }

    render() {
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>

                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>{this.state.workplace === "cabinet" ? "Présentation du cabinet" : (this.state.workplace === "centre de santé" ? "Présentation du centre de santé" :(this.state.workplace === "clinique" ? "Présentation de la clinique" : "Présentation du docteur"))}</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => this.addVideos()}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ margin: 0 }}>
                                        <h3>Vidéothèque</h3>
                                        <p>Ajoutez vos liens de vidéos Youtube dans cette rubrique</p>


                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Nom de la video</Label>
                                                    <Input
                                                        invalid={this.state.errors["name"]}
                                                        value={this.state.videoRow.name}
                                                        onChange={(e) => {
                                                            this.setState(
                                                                prevState => ({
                                                                    videoRow: {                   // object that we want to update
                                                                        ...prevState.videoRow,    // keep all other key-value pairs
                                                                        name: e.target.value       // update the value of specific key
                                                                    }
                                                                })
                                                            )
                                                        }}
                                                        placeHolder="Nom de la video ici .."
                                                    />
                                                    <FormFeedback invalid>{this.state.errors["name"]}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Lien de la video</Label>
                                                    <Input
                                                        invalid={this.state.errors["videoPath"]}
                                                        value={this.state.videoRow.videoPath}
                                                        onChange={(e) => {
                                                            this.setState(
                                                                prevState => ({
                                                                    videoRow: {                   // object that we want to update
                                                                        ...prevState.videoRow,    // keep all other key-value pairs
                                                                        videoPath: e.target.value       // update the value of specific key
                                                                    }
                                                                })
                                                            )

                                                        }}
                                                        placeHolder="Lien de la video ici .."
                                                    />
                                                    <FormFeedback invalid>{this.state.errors["videoPath"]}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <div style={{ marginTop: 'auto' }}>
                                                <IconButton
                                                    onClick={() => {
                                                        if (this.handleValidation()) {
                                                            this.getVideoRow();
                                                            this.setState(
                                                                prevState => ({
                                                                    videoRow: {                   // object that we want to update
                                                                        ...prevState.videoRow,    // keep all other key-value pairs
                                                                        videoPath: "",
                                                                        name: ""       // update the value of specific key
                                                                    }
                                                                })
                                                            )
                                                        }
                                                    }}
                                                    color="primary">
                                                    <AiOutlinePlus size={20} ></AiOutlinePlus>
                                                </IconButton>
                                            </div>
                                        </Row>

                                        {this.state.videoRows.map((videoRow, index) => {
                                            return (

                                                <div key={index}>

                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Nom de la video</Label>
                                                                <Input
                                                                    value={videoRow.name}
                                                                    onChange={(e) => {
                                                                        this.handleVideoChange(index, 'name', e.target.value)
                                                                    }}
                                                                    placeHolder="Nom de la video ici .."
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Lien de la video</Label>
                                                                <Input
                                                                    value={videoRow.videoPath}
                                                                    onChange={(e) => {
                                                                        this.handleVideoChange(index, 'videoPath', e.target.value)
                                                                    }}
                                                                    placeHolder="Détails ici .."
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <div style={{ marginTop: 'auto' }}>
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.removeVideo(videoRow)
                                                                }}
                                                                color="danger">
                                                                <AiOutlineMinus size={20} ></AiOutlineMinus>
                                                            </IconButton>
                                                        </div>
                                                    </Row>

                                                </div>

                                            )
                                        })}
                                    </Card>
                                </Col>


                            </Row>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Video
