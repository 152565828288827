import { Button } from '@laazyry/sobrus-design-system';
import React, { Component } from 'react'
import Header from '../helpers/Header/Header';
import notFound from '../images/notFound.svg';
import logo from "../images/logos/logo.png";
import { Link } from 'react-router-dom';

class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <div className="monSite-notFoundHeader">
                    <Link to={"/"}>
                        <img src={logo} width="100" />
                    </Link>
                </div>
                <div className="monSite-notFoundBody">
                    <h3>
                        OUPS !
                    </h3>
                    <p>
                        Cette page n’existe pas !
                    </p>
                    <img src={notFound} alt="not_found" />
                    <Link to={"/"}>
                        <Button
                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                            // outline
                            color="primary"
                        // className="btn-outline"
                        // onClick={() => this.postDoctorData()}
                        >
                            Accueil
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
}
export default NotFoundPage;