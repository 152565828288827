import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import './HeaderItem.css';

class HeaderItem extends Component {
    render() {
        return (
            <NavLink {...this.props} />
        )
    }
}
export default HeaderItem;