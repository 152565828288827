import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../images/logos/logo.png";
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavItem_more,
    NavUserProfile,
    DropdownItem,
    NavLogoutLink,
    Button,
    ButtonGroup,
    CustomButton,
    FormGroup,
    Label,
    Input,
    FormText,
    FormFeedback,
    NavItemMore,
    NavItemMoreBtn,
    NavItemMoreSubMenu,
    NavSubMenuLink,
    NavSubMenuItem,
    NavSubMenu,
} from "@laazyry/sobrus-design-system";
import HeaderItem from "./HeaderItem";
import UserService from "../../services/UserService";
import Store from '../../store/configStore';
import NavProfileLink from './navProfileLink/NavProfileLink';


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            loading: true,
            headerItems: [
                {
                    to: "/dashboard",
                    itemTitle: "Tableau de bord"
                },
                {
                    to: "/settings",
                    itemTitle: "Paramètres"
                }
            ],
        }
    }
    getCurrentUser = async () => {
        this.setState({ user: Store.getState()?.userData?.userData?.firstName + " " + Store.getState()?.userData?.userData?.lastName, loading: false });
    };
    logout = async () => {
        try {
            await UserService.logout();
        } catch (error) {
            console.warn(error);
        }
    }
    componentDidMount() {
        this.getCurrentUser();
    }
    render() {
        return (
            <div>

                <Navbar style={{ padding: "0 3%",minWidth: "1166px" }}>
                    <NavbarBrand>
                        <Link to={"/"}>
                            <img src={logo} width="100" />
                        </Link>
                    </NavbarBrand>
                    <Collapse>
                        <Nav>
                            {
                                this.state.headerItems.map((headerItem, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink tag={HeaderItem} to={headerItem.to}>
                                                {headerItem.itemTitle}
                                            </NavLink>
                                        </NavItem>
                                    );
                                })
                            }
                            <NavItemMore>
                                <NavItemMoreBtn title="Plus" />
                                <NavItemMoreSubMenu />
                            </NavItemMore>
                        </Nav>
                        {/* user infos  */}
                        <NavUserProfile fullName={this.state.user}>
                            <DropdownItem>
                                <NavProfileLink href={"https://" + process.env.REACT_APP_ACCOUNT_DOMAINE_NAME + "/account"} />
                            </DropdownItem>
                            <DropdownItem className="last-dropdown-item">
                                <NavLogoutLink onClick={() => this.logout()} />
                            </DropdownItem>
                        </NavUserProfile>
                        {/* end user infos  */}
                    </Collapse>
                </Navbar>

                {/* <Navbar style={{ padding: "0 3%" }}>
                    <NavbarBrand>
                        <Link to={"/settings"}>
                            <img src={logo} width="100" />
                        </Link>
                    </NavbarBrand>
                    <Collapse>
                        <Nav>
                            {this.state.headerItems.map((headerItem, index) => {
                                return (
                                    <NavItem key={index}>
                                        <NavLink tag={HeaderItem} to={headerItem.to}>
                                            {headerItem.itemTitle}
                                        </NavLink>
                                    </NavItem>
                                );
                            })}
                        </Nav>




                        <NavUserProfile fullName={this.state.user}>
                            <DropdownItem className="last-dropdown-item">
                                <NavLogoutLink onClick={() => {
                                    this.logout()
                                }} />
                            </DropdownItem>
                        </NavUserProfile>
                    </Collapse>
                </Navbar> */}

            </div>
        )
    }
}
export default Header;