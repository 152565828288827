import { ImagePicker, Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, Container, FormGroup, Input, InputSelect, Label, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import stepsHeaderIcon from '../../images/icons/stepsHeaderIcon.svg'
import arrowForward from '../../images/icons/arrowForward.svg'
import favImg from '../../images/favImg.svg'
import SideMenu from '../../components/Monsite/SideMenu';
import FontPicker from "font-picker-react";
import VisualParamService from '../../services/VisualParamService';
import ModalComponent from '../../components/Monsite/ModalComponent';
import warningIcon from "../../images/icons/warningIcon.svg";
import defaultLogo from "../../images/default/defaultLogo.svg";
import Header from '../../helpers/Header/Header';
import Store from '../../store/configStore';

class VisualParams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subdomain: Store.getState()?.userData?.userData?.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            BASE_URL: process.env.REACT_APP_API_URL,
            showAlert: false,
            dataIsChanged: false,
            imagesDataLogo: [],
            imagesDataIcon: [],
            color: "#000000",
            activeFontFamily: "Poppins",
            logo: "",
            favIcon: "",
            workplace: "",
            is_loaded: false,
            existingDoctor: false
        }
    }

    getApplicationParams = async () => {
        try {
            let response = await VisualParamService.getApplicationParams();
            if (response.data !== "") {
                this.setState({
                    color: response.data.primaryColor,
                    activeFontFamily: response.data.primaryFont,
                    logo: response.data.logo,
                    workplace: response.data.workplace,
                    favIcon: response.data.favIcon,
                    imagesDataLogo: response.data.logo ? [
                        ...this.state.imagesDataLogo,
                        {
                            ...this.state.imagesDataLogo[0],
                            source: `${this.state.BASE_URL}/uploads/images/logos/${response.data.logo}`,
                            //source: `http://127.0.0.1:8001/uploads/images/logos/${response.data.logo}`,
                            file: response.data.logo,
                        }] : [],
                    imagesDataIcon: response.data.favIcon ? [
                        ...this.state.imagesDataIcon,
                        {
                            ...this.state.imagesDataIcon[0],
                            source: `${this.state.BASE_URL}/uploads/images/favicons/${response.data.favIcon}`,
                            //source: `http://127.0.0.1:8001/uploads/images/favicons/${response.data.favIcon}`,
                            file: response.data.favIcon,
                        }] : [],
                    existingDoctor: true
                })
            } else {
                this.setState({
                    color: "#006093",
                    activeFontFamily: "Poppins",
                    logo: defaultLogo,
                    workplace: "",
                    favIcon: defaultLogo,
                    imagesDataLogo: [
                        ...this.state.imagesDataLogo,
                        {
                            ...this.state.imagesDataLogo[0],
                            source: defaultLogo,
                            file: defaultLogo,
                        }],
                    imagesDataIcon: [
                        ...this.state.imagesDataIcon,
                        {
                            ...this.state.imagesDataIcon[0],
                            source: defaultLogo,
                            file: defaultLogo,
                        }],
                })
            }
            this.setState({
                is_loaded: true
            })
        } catch (error) {
            console.error('applicationParams', error)
        }
    }

    capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    postApplicationParams = async () => {
        console.log(this.state.imagesDataLogo[0]?.file)
        this.setState({ is_loaded: false })
        let formData = new FormData();
        if (this.state.imagesDataLogo.length !== 0) {
            formData.append("logo", this.state.imagesDataLogo[0].file);
        }
        if (this.state.imagesDataIcon.length !== 0) {
            formData.append("favIcon", this.state.imagesDataIcon[0].file);
        }
        formData.append("primaryColor", this.state.color);
        if(this.state.workplace){
            formData.append("workplace", this.state.workplace);
        }
        formData.append("primaryFont", this.state.activeFontFamily);

        try {
            if (!this.state.existingDoctor) {
                await VisualParamService.addApplicationParams(formData);
            } else {
                await VisualParamService.updateApplicationParams(formData);
            }
            this.setState({ is_loaded: true, dataIsChanged: false }, () => {
                window.location.reload();
                //notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 4000 })
            })
        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }

    componentDidMount() {
        this.getApplicationParams();
    }
    changeImage(e) {
        this.setState({ logo: e })
    }


    handleShowAlert = (data) => {
        this.setState({
            showAlert: data
        })
    }
    alertBody = () => {
        return (
            <div>
                <Row>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                        <div className="monSite-alertIcon" >
                            <img src={warningIcon} />
                        </div>
                        <div className="monSite-stepsTitle">
                            <h4>Enregistrez les changements avant de quitter</h4>
                        </div>
                        <div className="monSite-stepsDescription">
                            <p>Merci d’enregistrer les changements opérés avant de visualiser votre site web</p>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
    alertFooter = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

                <Button
                    outline
                    color="warning"
                    onClick={() => {
                        this.setState({
                            showAlert: false
                        })
                    }}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        this.postApplicationParams()
                        this.setState({
                            showAlert: false
                        })

                    }}
                >
                    Enregistrer
                </Button>
            </div>
        )
    }

    render() {
        return (

            <div>

                <Header />
                <div className="monSite-container">
                    {this.state.showAlert && (
                        <div className="alertModal">
                            <ModalComponent
                                hideHeader={true}
                                onClose={() => this.setState({ showAlert: false })}
                                isOpen={this.state.showAlert}
                                body={this.alertBody()}
                                footer={this.alertFooter()}
                            />
                        </div>
                    )}
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>
                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Personnalisation</BreadcrumbItem>
                                        <BreadcrumbItem active>Identité visuelle</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        color="primary"
                                        onClick={() => {
                                            this.postApplicationParams()
                                            this.setState({
                                                showAlert: false
                                            })
                                        }}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu dataIsChanged={this.state.dataIsChanged} handleShowAlert={this.handleShowAlert} />
                                    </Card>
                                </Col>
                                <Col >
                                    <Card style={{ margin: 0 }}>
                                    <Row>
                                            <div className="monSite-logoParams">
                                                <Col xs="4">
                                                    <div>
                                                        <div className="monSite-logoParams__title">
                                                            <h3>Lieu d'exercice</h3>
                                                        </div>
                                                        <div className="monSite-logoParams__description">
                                                            <p>Sélectionnez votre lieu d'exercice</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{display:'flex', justifyContent: 'center'}}>
                                                        <div style={{width:'45%'}}>
                                                            <InputSelect
                                                                value={this.state.workplace && { value: this.state.workplace, label: this.capitalizeFirst(this.state.workplace) }}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        workplace: e.value
                                                                    })
                                                                }}
                                                                options={[{ value: "cabinet", label: "Cabinet" }, { value: "clinique", label: "Clinique" }, { value: "centre de santé", label: "Centre de santé" }, { value: "à domicile", label: "À domicile" }]}
                                                            ></InputSelect>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <div className="monSite-logoParams">
                                                <Col xs="4">
                                                    <div>
                                                        <div className="monSite-logoParams__title">
                                                            <h3>Choisissez votre logo</h3>
                                                        </div>
                                                        <div className="monSite-logoParams__description">
                                                            <p>Le logo s’affichera sur le menu du site ainsi qu’au niveau du pied de page du site web</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{display:'flex', justifyContent:'center'}}>

                                                        <ImagePicker
                                                            horizontal={true}
                                                            desc="Sélectionnez ou Glissez-déposez la photo du logo ici .."
                                                            initialState={this.state.imagesDataLogo}
                                                            imagesData={this.state.imagesDataLogo}
                                                            setImagesData={(imagesDataLogo) => this.setState({ imagesDataLogo })}
                                                            onRemove={(callback) => callback()}
                                                        ></ImagePicker>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <div className="monSite-logoParams">
                                                <Col xs="4">
                                                    <div className="monSite-logoParams__title">
                                                        <h3>Choisissez votre favicon</h3>
                                                    </div>
                                                    <div className="monSite-logoParams__description">
                                                        <p>La favicon s'affichera au niveau de l'onglet du navigateur</p>
                                                    </div>
                                                    <div className="monSite-logoParams__image monSite-favIcon">
                                                        <img src={favImg} />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                        <ImagePicker
                                                            horizontal={true}
                                                            desc="Sélectionnez ou Glissez-déposez la photo du favIcon ici .."
                                                            initialState={this.state.imagesDataIcon}
                                                            imagesData={this.state.imagesDataIcon}
                                                            setImagesData={(imagesDataIcon) => this.setState({ imagesDataIcon })}
                                                            onRemove={(callback) => callback()}
                                                        ></ImagePicker>
                                                        {/* <small>La taille de favicon 32x32px ou 64x64px</small> */}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <div className="monSite-logoParams">
                                                <Col>
                                                    <div className="monSite-logoParams__title">
                                                        <h3>Couleurs / Typographie</h3>
                                                    </div>
                                                    <div className="monSite-logoParams__description">
                                                        <p>Au cours de choix de la couleur de votre site web, <strong><u>assurez-vous que le texte est lisible.</u></strong></p>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>
                                                            Choisissez votre couleur principale
                                                        </Label>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                            <Input
                                                                isClearable
                                                                type="color"
                                                                id="color-picker"
                                                                value={this.state.color}
                                                                onChange={(e) => {
                                                                    this.setState({ color: e.target.value, dataIsChanged: true })
                                                                }}
                                                            ></Input>
                                                            <Input
                                                                isClearable
                                                                type="text"
                                                                id="color-picker"
                                                                value={this.state.color}
                                                                onChange={(e) => {
                                                                    this.setState({ color: e.target.value, dataIsChanged: true })
                                                                }}
                                                            ></Input>

                                                        </div>

                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>
                                                            Choisissez votre police de caractère
                                                        </Label>
                                                        <FontPicker
                                                            apiKey="AIzaSyD4p81b_Gp555B4E37weDXkLbH-0I_wFKY"
                                                            activeFontFamily={this.state.activeFontFamily}
                                                            onChange={(nextFont) =>
                                                                this.setState({
                                                                    activeFontFamily: nextFont.family,
                                                                    dataIsChanged: true
                                                                })
                                                            }
                                                        />
                                                    </FormGroup>

                                                </Col>
                                            </div>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
export default withRouter(VisualParams);
