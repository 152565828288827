import { ImagePicker, Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, DashedButton, FormFeedback, FormGroup, FormText, IconButton, Input, Label, NavItem_more, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import loading from '../../images/icons/loading.svg'
import SideMenu from '../../components/Monsite/SideMenu'
import ModalComponent from '../../components/Monsite/ModalComponent'
import SlideService from '../../services/SlideService'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import Header from '../../helpers/Header/Header'
import Store from '../../store/configStore';

export class Slide extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            BASE_URL: process.env.REACT_APP_API_URL,
            visibleSlide: 2,
            imagesData: [],
            localPictures: [],
            is_loaded: false,
            key: null,
            showModal: false,
            update: false,
            data: [],
            existingDoctor: false,
            errors: {},
            slide: {
                id: "",
                title: "",
                description: "",
                slidePicture: "",
                slideOrder: "",
                link: "",
                callToActionName: "",
                status: true
            }
        }
    }
    openModal = () => {
        this.setState({ showModal: !this.state.showModal })
    }
    componentDidMount() {
        this.getSlides();
    }
    getSlides = async () => {
        try {
            let response = await SlideService.getSlides();
            if (response.data !== "") {
                this.setState({
                    data: response.data,
                    existingDoctor: true
                })
            }
            this.setState({
                is_loaded: true
            })
        } catch (error) {
            console.error('applicationParams', error)
        }
    }
    postSlides = async () => {
        this.setState({ is_loaded: false })
        let data = this.state.data;
        let formData = new FormData();
        data.forEach((d, index) => {
            formData.append(`data[${index}][id]`, d.id);
            formData.append(`data[${index}][title]`, d.title);
            formData.append(`data[${index}][description]`, d.description);
            formData.append(`data[${index}][slidePicture]`, d.slidePicture);
            formData.append(`data[${index}][slideOrder]`, index + 1);
            formData.append(`data[${index}][link]`, d.link);
            formData.append(`data[${index}][callToActionName]`, d.callToActionName);
            formData.append(`data[${index}][status]`, true);
        })
        try {
            await SlideService.addSlides(formData)
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les sliders ont été ajoutés avec succès", delay: 4000 }))

        } catch (error) {
            this.setState({ is_loaded: true }, () => notify({ type: "danger", msg: "Une erreur est survenue", delay: 4000 }))
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;
        if (this.state.imagesData && this.state.imagesData.length === 0) {
            formIsValid = false;
            errors["slidePicture"] = "Ce champ est obligatoire";
        }
        if (this.state.slide.title === '') {
            formIsValid = false;
            errors["title"] = "Ce champ est obligatoire";
        }
        // if (this.state.slide.description === '') {
        //     formIsValid = false;
        //     errors["description"] = "Ce champ est obligatoire";
        // }

        if (this.state.slide.callToActionName !== '' && this.state.slide.link === "") {
            formIsValid = false;
            errors["link"] = "Ce champ est obligatoire";
        }

        if (this.state.slide.link !== '' && this.state.slide.callToActionName === "") {
            formIsValid = false;
            errors["callToActionName"] = "Ce champ est obligatoire";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    substringMethod(string) {
        return string.split(':')[0]
    }

    modalHeader = () => {
        return (
            <div>
                Ajouter nouveau slider
            </div>
        )
    }
    modalBody = () => {
        return (
            <div>
                <Row>

                    <Col>
                        <p>Image de slider*</p>
                        <FormGroup>
                            <ImagePicker
                                initialState={this.state.imagesData}
                                imagesData={this.state.imagesData}
                                setImagesData={(imagesData) => this.setState({ imagesData })}
                                onRemove={(callback) => callback()}
                                horizontal={false}
                                desc="Sélectionnez ou Glissez-déposez votre image de slider ici .."
                            ></ImagePicker>

                            <small style={{ color: "#f05a29", fontSize: ".775rem" }}>{this.state.errors["slidePicture"]}</small>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <div>
                                <Label>
                                    Titre*
                                </Label>
                                <Input
                                    invalid={this.state.errors["title"]}
                                    isClearable
                                    placeHolder="Votre sous-titre ici .."
                                    value={this.state.slide.title}
                                    onChange={(e) => this.setState({ slide: { ...this.state.slide, title: e.target.value } })}
                                />
                                <FormFeedback invalid>{this.state.errors["title"]}</FormFeedback>
                            </div>
                            <div>
                                <Label>
                                    Description
                                </Label>
                                <Input
                                    invalid={this.state.errors["description"]}
                                    isClearable
                                    type="textarea"
                                    rows="5"
                                    placeHolder="Votre description ici …"
                                    value={this.state.slide.description}
                                    onChange={(e) => this.setState({ slide: { ...this.state.slide, description: e.target.value } })}
                                />
                                <FormFeedback invalid>{this.state.errors["description"]}</FormFeedback>
                            </div>
                            <div>
                                <Label>
                                    Label de bouton de redirection
                                </Label>
                                <Input
                                    invalid={this.state.errors["callToActionName"]}
                                    placeHolder="Votre label ici …"
                                    value={this.state.slide.callToActionName}
                                    onChange={(e) => this.setState({ slide: { ...this.state.slide, callToActionName: e.target.value } })}
                                />
                                <FormFeedback invalid>{this.state.errors["callToActionName"]}</FormFeedback>
                            </div>
                            <div>
                                <Label>
                                    Lien de bouton de redirection
                                </Label>
                                <Input
                                    invalid={this.state.errors["link"]}
                                    placeHolder="Lien ici …"
                                    value={this.state.slide.link}
                                    onChange={(e) => this.setState({ slide: { ...this.state.slide, link: e.target.value } })}
                                />
                                <FormFeedback invalid>{this.state.errors["link"]}</FormFeedback>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }
    modalFooter = () => {
        return (
            <div>

                <Button
                    outline
                    color="warning"
                    onClick={() => {
                        this.setState({
                            imagesData: [],
                            showModal: false,
                            update: false,
                            errors: {},
                            slide: {
                                id: "",
                                title: "",
                                description: "",
                                slidePicture: "",
                                slideOrder: "",
                                link: "",
                                callToActionName: "",
                                status: true
                            }
                        })
                    }}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        // this.postSlides()
                        if (this.handleValidation()) {
                            this.setState({ slide: { ...this.state.slide, slidePicture: this.state.imagesData !== [] ? this.state.imagesData[0]?.file : "" } }, () => {
                                if (this.state.update) {
                                    this.handleSlideChange(this.state.key, this.state.slide)
                                } else {
                                    this.state.data.unshift(this.state.slide);
                                }

                                this.setState({
                                    imagesData: [],
                                    showModal: false,
                                    errors: {},
                                    slide: {
                                        title: "",
                                        description: "",
                                        slidePicture: "",
                                        slideOrder: "",
                                        link: "",
                                        callToActionName: "",
                                        status: true
                                    }
                                })
                            })
                        }



                    }}
                >
                    Enregistrer
                </Button>
            </div>
        )
    }



    handleSlideChange(index, value) {
        let slides = this.state.data;
        slides[index] = value;
        this.setState({ data: slides, update: false });

    }

    removeSlide(e) {
        var array = this.state.data; // make a separate copy of the array
        var index = array.indexOf(e)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ data: array });
        }
    }

    handleLoadMoreClick() {

        this.setState({
            visibleSlide: this.state.visibleSlide + 2
        })
    }

    render() {
        const { showModal, data } = this.state;
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>
                            {showModal && (

                                <ModalComponent
                                    onClose={() => this.setState({ showModal: false })}
                                    isOpen={showModal}
                                    header={this.modalHeader()}
                                    body={this.modalBody()}
                                    footer={this.modalFooter()}
                                />

                            )}

                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>Sliders</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            // className="btn-outline"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => this.postSlides()}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ margin: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <h3>Sliders</h3> <span style={{ marginLeft: "1rem", backgroundColor: "#00B1D815", color: "#02829F", fontSize: "16px" }}>Vous avez ajouté  <strong>{data?.length}</strong>  sliders</span>
                                        </div>

                                        <DashedButton
                                            onClick={() => {
                                                this.setState({ showModal: true })
                                            }}>
                                            Ajouter slider
                                        </DashedButton>

                                        {data && data.slice(0, this.state.visibleSlide).map((item, index) => {
                                            return (

                                                <div className="monSite-slide" key={index}>
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div className="monSite-slide__image">
                                                            {item.slidePicture && (
                                                                <img src={typeof (item.slidePicture) === "string" ? (this.substringMethod(item?.slidePicture) === 'blob' ? item?.slidePicture : `${this.state.BASE_URL}/uploads/images/slides/${item?.slidePicture}`) : URL.createObjectURL(item?.slidePicture)} />
                                                                // <img src={typeof (item.slidePicture) === "string" ? (this.substringMethod(item?.slidePicture) === 'blob' ? item?.slidePicture : `http://127.0.0.1:8000/uploads/images/slides/${item?.slidePicture}`) : URL.createObjectURL(item?.slidePicture)} />
                                                            )}
                                                        </div>
                                                        <div className="monSite-slide__body">
                                                            <div className="monSite-slide__body__title">
                                                                <h3>{item.title}</h3>
                                                            </div>
                                                            <div className="monSite-slide__body__description">
                                                                <p>{item.description}</p>
                                                            </div>
                                                            {item.link && (
                                                                <div className="monSite-slide__body__button">
                                                                    <a>

                                                                        <Button
                                                                            style={{ marginLeft: "0" }}
                                                                            color="primary"
                                                                            onClick={() => window.open("https://" + item.link, "_blank")}
                                                                        >
                                                                            {item.callToActionName}
                                                                        </Button>
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="monSite-slide__icons">
                                                        <IconButton
                                                            onClick={() =>
                                                                this.setState({
                                                                    showModal: true,
                                                                    slide: item,
                                                                    update: true,
                                                                    key: index,
                                                                    id: item.id,
                                                                    imagesData: [
                                                                        ...this.state.imagesData,
                                                                        {
                                                                            ...this.state.imagesData[0],
                                                                            source: typeof (item.slidePicture) === "string" ? (this.substringMethod(item?.slidePicture) === 'blob' ? item?.slidePicture : `${this.state.BASE_URL}/uploads/images/slides/${item?.slidePicture}`) : URL.createObjectURL(item.slidePicture),
                                                                            file: item.slidePicture,
                                                                        }]
                                                                })}
                                                            color="primary">
                                                            <FiEdit3 size={20} />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => { this.removeSlide(item) }}
                                                            color="danger">
                                                            <FiTrash2 size={20} />
                                                        </IconButton>
                                                    </div>
                                                </div>

                                            )
                                        })}
                                        {(this.state.visibleSlide < this.state.data.length) && (
                                            <div>
                                                <div
                                                    style={{ cursor: "pointer", backgroundColor: "#55ACED14", display: "flex", justifyContent: "center", alignItems: "center", height: "73px", borderRadius: "8px" }}
                                                    onClick={() => {
                                                        this.handleLoadMoreClick()
                                                    }}
                                                >
                                                    <img src={loading} style={{ marginRight: "1rem", color: "#02829F", fontSize: "25px" }} />
                                                    <strong style={{ marginRight: "1rem", color: "#02829F" }}>Charger plus</strong>
                                                </div>
                                            </div>
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Slide
