import { SpinnerLoading } from "@laazyry/sobrus-design-system";
import React, { Component } from "react";
import VisualParams from "../../pages/Monsite/VisualParams";
import UserService from "../../services/UserService";
import { Route, Redirect } from "react-router-dom";
import Store from '../../store/configStore';
import VisualParamService from '../../services/VisualParamService';

class AuthenticationChecker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    this.getApplicationParams();
    this.testingRequest();
  }

  testingRequest = async () => {
    try {
      this.setState({ loading: true }, async () => {
        let res = await UserService.getUser();
        Store.dispatch({ type: 'SET_USER_DATA', value: res?.data });
        
        if (res?.data) {
          this.setState({ loading: false, isAuthenticated: true });
        } else {
          this.setState({ loading: false, isAuthenticated: false });
        }
      });
    } catch (error) {
      this.setState({ loading: false, isAuthenticated: false });
    }
  };

  getApplicationParams = async () => {
    try {
        let response = await VisualParamService.getApplicationParams();
        if (response.data !== "") {
          Store.dispatch({ type: 'SET_WORKPLACE', value: response?.data?.workplace });
        }
    } catch (error) {
        console.error('applicationParams', error)
    }
}

  render() {
    const { loading, isAuthenticated } = this.state;
    return (
      <>
        {loading ? (
          <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SpinnerLoading loading />
          </div>
        ) : isAuthenticated ? (
          this.props.children
        ) : (
          <Redirect to={"/auth/login"} />
        )}
      </>
    );
  }
}

export default AuthenticationChecker;