import { Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, CardBody, CardComment, CardHeader, CardLikeAndComment, Col, DashboardCardBody, DoctorCard, DoctorCardAvatar, DoctorCardInfosContainer, DoctorCardName, DoctorCardSpecialty, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, notify, PostCard, Row, SpinnerLoading, StatisticalCard, SupportBtn } from '@laazyry/sobrus-design-system'
import moment from 'moment'
import React, { Component } from 'react'
import Header from '../../helpers/Header/Header'
import PostService from '../../services/PostService'
import StatsService from '../../services/StatsService'
import avatarSob from '../../images/avatarsob.svg'
import Store from '../../store/configStore';
import InfiniteScroll from 'react-infinite-scroll-component'
import loading from '../../images/icons/loading.svg'
// import visits from '../../images/icons/Visites.svg'
import itiniraires from '../../images/icons/Itiniraires.svg'
import visits from '../../images/icons/appels.svg'
import { FiEye, FiMap, FiPhoneCall } from 'react-icons/fi';
import ModalComponent from '../../components/Monsite/ModalComponent'

class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            is_loaded: false,
            actions: [],
            posts: [],
            view: false,
            call: false,
            itinerary: false,
            totalPostItems: 0,
            limit: 5,
            showModal: false,
        }
    }

    componentDidMount() {
        this.getActions();
        this.getPosts();

    }

    status(post) {
        let timeEnd = moment(new Date());
        let timeStart = moment(post.createdAt);
        const diff = timeEnd.diff(timeStart);
        const diffDuration = moment.duration(diff);
        console.log(diffDuration.days())
        if (diffDuration.days() <= 3) {
            return true;
        } else {
            return false;
        }
    }

    loadMorePosts = () => {
        setTimeout(() => {
            this.setState({ limit: this.state.limit + 5 }, () => {
                console.log(this.state.limit);
                this.getPosts()
            })
        }, 2000)
    }

    getActions = async () => {
        try {
            let response = await StatsService.getActions();
            if (response.data !== "") {
                this.setState({
                    actions: response.data,
                }, () => {
                    this.state.actions.forEach(el => {
                        if (el.action_type === "view") {
                            this.setState({ view: true });
                        }
                        if (el.action_type === "call") {
                            this.setState({ call: true });
                        }
                        if (el.action_type === "itinerary") {
                            this.setState({ itinerary: true });
                        }
                    });
                })
            }
            this.setState({
                is_loaded: true
            })
        } catch (error) {
            console.error('applicationParams', error)
        }
    }
    getPosts = async () => {
        try {
            let response = await PostService.getPosts(this.state.limit);
            if (response.data !== "") {
                // let dataTest = [];
                // [1, 1, 1, 1, 1, 1, 1, 1].forEach(el => {
                //     dataTest.push(response.data.posts[0])
                // });
                this.setState({
                    posts: response.data.posts,
                    totalPostItems: response.data.totalItems
                })
            }
            this.setState({
                is_loaded: true
            })

        } catch (error) {
            console.error('applicationParams', error)
        }
    }

    render() {
        let { actions, posts, view, call, itinerary, showModal } = this.state;
        return (
            <div>
                {console.log(showModal)}
                {showModal && (
                    <div>
                        {/* hello */}
                    </div>
                    // <ModalComponent
                    //     className="alertModal"
                    //     isOpen={showModal}
                    //     hideCloseBtn
                    //     // header={this.modalHeader()}
                    //     // body={this.modalBody()}
                    //     // footer={this.modalFooter()}
                    // />

                )}
                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>
                            <div className="monSite-breadcrumb">
                                <div style={{ width: "100%", margin: "1rem 0", display: "flex", alignItems: "end", justifyContent: "space-between" }}>
                                    <Breadcrumb>
                                        <BreadcrumbTitle>Tableau de bord</BreadcrumbTitle>
                                        <BreadcrumbBody>
                                            <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/dashboard`) }}>Tableau de bord</a></BreadcrumbItem>
                                            <BreadcrumbItem active>Fil d'actualité</BreadcrumbItem>
                                        </BreadcrumbBody>
                                    </Breadcrumb>
                                    <SupportBtn
                                        position="bottom right"
                                        data={{
                                            email: 'support@sobrus.com',
                                            phoneNumber: '05 30 500 500',
                                            showClientRef: false,
                                            showEmail: true,
                                            showWorkHours: true,
                                            workHours: [
                                                {
                                                    afternoon: '14:00 à 18:00',
                                                    label: 'Du lundi au Vendredi',
                                                    morning: '09:00 à 13:00'
                                                },
                                                {
                                                    label: 'Le Samedi',
                                                    morning: '09:00 à 13:00'
                                                }
                                            ]
                                        }}
                                    />
                                </div>

                            </div>
                            <div>
                                <Row>
                                    <Col xs="3">
                                        <Card style={{ margin: 0 }}>
                                            <DoctorCard style={{ margin: 0 }}>
                                                <DoctorCardAvatar src={avatarSob} />
                                                <DoctorCardInfosContainer>
                                                    <DoctorCardName>Dr. {Store.getState()?.userData?.userData?.firstName + " " + Store.getState()?.userData?.userData?.lastName}</DoctorCardName>
                                                    {/* <DoctorCardSpecialty>Médecin généraliste</DoctorCardSpecialty> */}
                                                </DoctorCardInfosContainer>
                                            </DoctorCard>
                                        </Card>
                                    </Col>
                                    <Col xs="6" >
                                        <InfiniteScroll
                                            dataLength={this.state.totalPostItems} //This is important field to render the next data
                                            next={this.loadMorePosts}
                                            hasMore={this.state.totalPostItems > this.state.posts.length}
                                            loader={
                                                <div
                                                    style={{ cursor: "pointer", backgroundColor: "#55ACED14", display: "flex", justifyContent: "center", alignItems: "center", height: "73px", borderRadius: "8px", marginBottom: "1rem" }}
                                                >
                                                    <img className="monSite_loaderAnimation" src={loading} style={{ marginRight: "1rem", color: "#02829F", fontSize: "25px" }} />
                                                    <strong style={{ marginRight: "1rem", color: "#02829F" }}>Chargement</strong>
                                                </div>
                                            }
                                        >
                                            {posts?.map((post) => {
                                                return (
                                                    <div style={{ marginBottom: "1rem" }}>

                                                        <PostCard

                                                            children={[
                                                                <CardHeader
                                                                    image={avatarSob}
                                                                    docName={'MonSiteSobrus'}
                                                                    status={'Nouveau'}
                                                                    Date={moment(post?.createdAt).format("YYYY-MM-DD hh:mm")}
                                                                    showStatus={this.status(post)}
                                                                    statusStyle={{}} />,
                                                                <DashboardCardBody
                                                                    title={post?.title}
                                                                    imgOnclick={() => { }}
                                                                    source={post?.image}
                                                                    description={post?.description}
                                                                    isImage={true}
                                                                    imgResizeMode="cover" />,
                                                                // <CardLikeAndComment isLiked={true} likesNumber={'6'} commentNumber={'22'} />,
                                                                // <CardComment />
                                                            ]} />

                                                    </div>
                                                )
                                            })}
                                        </InfiniteScroll>

                                    </Col>
                                    <Col xs="3">
                                        <Card style={{ margin: 0 }}>

                                            {actions?.map((action) => {
                                                if (action?.action_type === "view" && view) {
                                                    return (
                                                        <StatisticalCard
                                                            number={action?.total ? action?.total : "0"}
                                                            desc="Total des visites jusqu'à aujourd'hui."
                                                            icon={<FiEye style={{ opacity: ".2", fontSize: "150px" }}/>}
                                                        />
                                                    )
                                                }
                                                if (action.action_type === "call" && call) {
                                                    return (
                                                        <StatisticalCard
                                                            number={action?.total ? action?.total : "0"}
                                                            desc="Total des appels jusqu'à aujourd'hui."
                                                            color="info"
                                                            icon={<FiPhoneCall style={{ opacity: ".2", fontSize: "150px" }}/>}
                                                        />
                                                    )
                                                }
                                                if (action.action_type === "itinerary" && itinerary) {
                                                    return (
                                                        <StatisticalCard
                                                            number={action?.total ? action?.total : "0"}
                                                            desc="Total des itinéraire jusqu'à aujourd'hui."
                                                            color="warning"
                                                            icon={<FiMap style={{ opacity: ".2", fontSize: "150px" }}/>}
                                                        />
                                                    )
                                                }
                                            })}
                                            <>
                                                {!view && (
                                                    <StatisticalCard
                                                        number="0"
                                                        desc="Total des visites jusqu'à aujourd'hui."
                                                        icon={<FiEye style={{ opacity: ".2", fontSize: "150px" }}/>}
                                                    />
                                                )}
                                                {!call && (
                                                    <StatisticalCard
                                                        number="0"
                                                        desc="Total des appels jusqu'à aujourd'hui."
                                                        color="info"
                                                        icon={<FiPhoneCall style={{ opacity: ".2", fontSize: "150px" }}/>}
                                                    />
                                                )}
                                                {!call && (
                                                    <StatisticalCard
                                                        number="0"
                                                        desc="Total des itinéraire jusqu'à aujourd'hui."
                                                        color="warning"
                                                        icon={<FiMap style={{ opacity: ".2", fontSize: "150px" }}/>}
                                                    />)
                                                }
                                            </>
                                        </Card>
                                    </Col>

                                </Row>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        )
    }
}
export default Dashboard