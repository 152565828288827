import React, { Component } from 'react'
import { Button, notify, Toast } from '@laazyry/sobrus-design-system';

class ToastComponent extends Component {
    render() {
        return (
            <div>
                <Button onClick={() => {
                    notify({ type: "info", msg: "test", delay: 2000 })
                }}>Open Toast</Button>
            </div>
        )
    }
}

export default ToastComponent
