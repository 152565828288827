import { ImagePicker, Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, DashedButton, IconButton, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import featherTrash from '../../images/icons/feather-trash.svg'
import featherEdit from '../../images/icons/feather-edit.svg'
import closeIcon from '../../images/icons/close.svg'
import SideMenu from '../../components/Monsite/SideMenu'
import ModalComponent from '../../components/Monsite/ModalComponent'
import TransformationService from '../../services/TransformationService'
import { FiEdit3, FiTrash2 } from 'react-icons/fi'
import { VscLoading } from 'react-icons/vsc'
import loading from '../../images/icons/loading.svg'
import Header from '../../helpers/Header/Header'
import Store from '../../store/configStore';

class Transformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData?.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            BASE_URL: process.env.REACT_APP_API_URL,
            visiblePictures: 2,
            imagesDataBefore: [],
            imagesDataAfter: [],
            key: null,
            update: false,
            is_loaded: false,
            showModal: false,
            data: [],
            picture: {
                id: "",
                name: "picture",
                pictureBefore: "",
                pictureAfter: ""
            }
        }
    }
    substringMethod(string) {
        return string.split(':')[0]
    }

    modalHeader = () => {
        return (
            <div>
                Ajouter nouvelle image
            </div>
        )
    }
    handleLoadMoreClick() {
        this.setState({
            visiblePictures: this.state.visiblePictures + 2
        })
    }
    modalBody = () => {
        return (
            <div>
                <Row>
                    <Col>
                        <p>Image avant</p>
                        <ImagePicker
                            imagesData={this.state.imagesDataBefore}
                            setImagesData={(imagesDataBefore) => this.setState({ imagesDataBefore })}
                            onRemove={(callback) => callback()}
                            horizontal={false}
                            desc="Sélectionnez ou Glissez-déposez votre image avant ici .."
                            initialState={this.state.imagesDataBefore}
                        ></ImagePicker>
                    </Col>
                    <Col>
                        <p>Image après</p>
                        <ImagePicker
                            imagesData={this.state.imagesDataAfter}
                            setImagesData={(imagesDataAfter) => this.setState({ imagesDataAfter })}
                            onRemove={(callback) => callback()}
                            horizontal={false}
                            desc="Sélectionnez ou Glissez-déposez votre image après ici .."
                            initialState={this.state.imagesDataAfter}
                        ></ImagePicker>
                    </Col>
                </Row>
            </div>
        )
    }
    modalFooter = () => {
        return (
            <div>
                <Button
                    outline
                    color="warning"
                    onClick={() => {
                        this.setState({
                            imagesDataBefore: [],
                            imagesDataAfter: [],
                            showModal: false,
                            picture: {
                                name: "",
                                pictureBefore: "",
                                pictureAfter: ""
                            }
                        })
                    }}
                >
                    Annuler
                </Button>
                <Button
                    color="primary"
                    onClick={() => {

                        if (this.state.imagesDataBefore && this.state.imagesDataBefore.length !== 0 && this.state.imagesDataAfter && this.state.imagesDataAfter.length !== 0) {
                            this.setState({ picture: { ...this.state.picture, pictureBefore: this.state.imagesDataBefore !== [] ? this.state.imagesDataBefore[0]?.file : "", pictureAfter: this.state.imagesDataAfter !== [] ? this.state.imagesDataAfter[0]?.file : "" } }, () => {

                                if (this.state.update) {
                                    this.handleTransformationChange(this.state.key, this.state.picture)
                                } else {
                                    this.state.data.unshift(this.state.picture)
                                }

                                // notify({ type: "success", msg: "L’image a été ajoutée avec succès", delay: 4000 })
                                this.setState({
                                    imagesDataBefore: [],
                                    imagesDataAfter: [],
                                    showModal: false,
                                    picture: {
                                        name: "",
                                        pictureBefore: "",
                                        pictureAfter: ""
                                    }
                                })
                            })
                        }
                        this.setState({
                            showModal: false,
                        })

                    }}
                >
                    Enregistrer
                </Button>
            </div>
        )
    }

    componentDidMount() {
        this.getPictures()
    }
    getPictures = async () => {
        try {
            let response = await TransformationService.getPictures();
            this.setState({
                data: response.data
            })
            this.setState({
                is_loaded: true
            })
        } catch (error) {
        }
    }
    postPictures = async () => {
        this.setState({ is_loaded: false })
        let data = this.state.data;
        let formData = new FormData();
        data.forEach((d, index) => {
            formData.append(`data[${index}][id]`, d.id);
            formData.append(`data[${index}][name]`, d.name);
            formData.append(`data[${index}][pictureBefore]`, d.pictureBefore);
            formData.append(`data[${index}][pictureAfter]`, d.pictureAfter);
        })
        try {
            await TransformationService.addPictures(formData)
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les images ont été ajoutées avec succès", delay: 3000 }))
        } catch (error) {
            this.setState({ is_loaded: true }, () =>
                notify({ type: "danger", msg: "Une erreur est survenue", delay: 3000 }))
        }
    }

    handleTransformationChange(index, value) {
        let pictures = this.state.data;
        pictures[index] = value;
        this.setState({ data: pictures, update: false });

    }

    removeTransformation(e) {
        var array = this.state.data; // make a separate copy of the array
        var index = array.indexOf(e)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ data: array });
        }
    }

    render() {
        const { showModal, data } = this.state;
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>

                            {showModal && (
                                <ModalComponent
                                    isOpen={showModal}
                                    hideCloseBtn={false}
                                    header={this.modalHeader()}
                                    body={this.modalBody()}
                                    footer={this.modalFooter()}
                                />
                            )}


                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>Avant / Après</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        color="primary"
                                        onClick={() => this.postPictures()}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                            {/* <Container> */}
                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>


                                    <Card style={{ margin: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <h3>Avant / Après</h3> <span style={{ marginLeft: "1rem", backgroundColor: "#00B1D815", color: "#02829F", fontSize: "16px" }}>Vous avez ajouté <strong>{data?.length}</strong> images avant / après</span>
                                        </div>
                                        <DashedButton
                                            onClick={() => {
                                                this.setState({ showModal: true })
                                            }}>
                                            Ajouter image avant/après
                                        </DashedButton>
                                        {this.state.data.slice(0, this.state.visiblePictures).map((picture, index) => {
                                            return (

                                                <div className="monSite-slide" key={index}>
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div>
                                                            <p>Image avant</p>
                                                            <div className="monSite-slide__image">
                                                                {picture?.pictureBefore && (
                                                                    <img src={typeof (picture?.pictureBefore) === "string" ? (this.substringMethod(picture?.pictureBefore) === 'blob' ? picture?.pictureBefore : `${this.state.BASE_URL}/uploads/images/transformations/${picture?.pictureBefore}`) : URL.createObjectURL(picture?.pictureBefore)} alt={'before'} />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>Image après</p>
                                                            <div className="monSite-slide__image">
                                                                {picture?.pictureAfter && (
                                                                    <img src={typeof (picture?.pictureAfter) === "string" ? (this.substringMethod(picture?.pictureAfter) === 'blob' ? picture?.pictureAfter : `${this.state.BASE_URL}/uploads/images/transformations/${picture?.pictureAfter}`) : URL.createObjectURL(picture?.pictureAfter)} alt={'after'} />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="monSite-slide__icons">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    imagesDataBefore: [
                                                                        ...this.state.imagesDataBefore,
                                                                        {
                                                                            ...this.state.imagesDataBefore[0],
                                                                            source: typeof (picture.pictureBefore) === "string" ? (this.substringMethod(picture.pictureBefore) === 'blob' ? picture.pictureBefore : `${this.state.BASE_URL}/uploads/images/transformations/${picture.pictureBefore}`) : URL.createObjectURL(picture.pictureBefore),
                                                                            file: picture.pictureBefore,
                                                                        }],
                                                                    imagesDataAfter: [
                                                                        ...this.state.imagesDataAfter,
                                                                        {
                                                                            ...this.state.imagesDataAfter[0],
                                                                            source: typeof (picture.pictureAfter) === "string" ? (this.substringMethod(picture.pictureAfter) === 'blob' ? picture.pictureAfter : `${this.state.BASE_URL}/uploads/images/transformations/${picture.pictureAfter}`) : URL.createObjectURL(picture.pictureAfter),
                                                                            file: picture.pictureAfter,
                                                                        }],
                                                                    showModal: true,
                                                                    picture: picture,
                                                                    update: true,
                                                                    key: index,
                                                                    id: picture.id
                                                                })
                                                            }}
                                                            color="primary">
                                                            <FiEdit3 size={20} />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.removeTransformation(picture)
                                                            }}
                                                            color="danger">
                                                            <FiTrash2 size={20} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {(this.state.visiblePictures < this.state.data.length) && (
                                            <div>
                                                <div
                                                    style={{ cursor: "pointer", backgroundColor: "#55ACED14", display: "flex", justifyContent: "center", alignItems: "center", height: "73px", borderRadius: "8px" }}
                                                    onClick={() => {
                                                        this.handleLoadMoreClick()
                                                    }}
                                                >
                                                    <img src={loading} style={{ marginRight: "1rem", color: "#02829F", fontSize: "25px" }} />
                                                    <strong style={{ marginRight: "1rem", color: "#02829F" }}>Charger plus</strong>
                                                </div>
                                            </div>
                                        )}
                                    </Card>

                                </Col>
                            </Row>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Transformation
