import { ImagePicker, MultiImagePicker, Breadcrumb, BreadcrumbBody, BreadcrumbItem, BreadcrumbTitle, Button, Card, Col, notify, Row, SpinnerLoading } from '@laazyry/sobrus-design-system'
import React, { Component } from 'react'
import SideMenu from '../../components/Monsite/SideMenu'
import Header from '../../helpers/Header/Header'
import HealthcareEstablishmentPictureService from '../../services/HealthcareEstablishmentPictureService'
import Store from '../../store/configStore';
class HealthCareEstablishmentImage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            subdomain: Store.getState()?.userData?.userData.subdomaine,
            DOMAINE: process.env.REACT_APP_DOMAINE_NAME,
            BASE_URL: process.env.REACT_APP_API_URL,
            is_loaded: false,
            imagesData: [],
            attributes: [],
        }
    }


    handleImagesData(index, field, value) {
        let cursus = this.state.imagesData;
        cursus[index] = { ...cursus[index] };
        cursus[index][field] = value;
        this.setState({ imagesData: cursus });

    }

    componentDidMount() {
        this.getPictures()
    }
    getPictures = async () => {
        this.setState({
            imagesData: []
        })
        try {
            let response = await HealthcareEstablishmentPictureService.getPictures();
            let attributesResponse = await HealthcareEstablishmentPictureService.getAllAttributes();
            attributesResponse.data.map((attribute, index) => {
                let attributes = this.state.attributes;
                attributes[index] = { ...attributes[index] };
                attributes[index]['value'] = attribute.id;
                attributes[index]['label'] = attribute.label;
                this.setState({ attributes: attributes });
            })


            response.data.forEach((data, index) => {
                let images = this.state.imagesData;
                images[index] = { ...images[index] };
                images[index]['source'] = `${this.state.BASE_URL}/uploads/images/healthcareEstablishment/` + data.path;
                images[index]['label'] = data.name;
                images[index]['attribute'] = data.attribute.id;
                // images[index]['uid'] = data.uid;
                images[index]['id'] = data.id;
                this.setState({ imagesData: images });
            });
            this.setState({
                is_loaded: true
            })
        } catch (error) {
            console.error('pictures', error)
        }
    }
    splitString(string, char) {
        return string.split(char)
    }
    postPictures = async () => {
        this.setState({ is_loaded: false })
        let data = this.state.imagesData;
        let formData = new FormData();
        data.forEach((d, index) => {

            formData.append(`images[${index}][id]`, d.id);
            formData.append(`images[${index}][name]`, d.label);
            formData.append(`images[${index}][image]`, d.file);
            formData.append(`images[${index}][attributeId]`, d.attribute);
            formData.append(`images[${index}][pictureOrder]`, index + 1);
        })
        try {
            await HealthcareEstablishmentPictureService.addPictures(formData)
            this.setState({ is_loaded: true }, () => notify({ type: "success", msg: "Les données ont été sauvegardées avec succés", delay: 3000 }))
        } catch (error) {
            if (error.response.data.message === "Attribute was not found") {
                this.setState({ is_loaded: true }, () =>
                    notify({ type: "warning", msg: "Veuillez choisir un attribut", delay: 3000 }))
            } else {
                this.setState({ is_loaded: true }, () =>
                    notify({ type: "danger", msg: "Une erreur est survenue", delay: 3000 }))
            }
        }
    }

    render() {
        return (
            <div>

                <Header />
                <div className="monSite-container">
                    {!this.state.is_loaded && (
                        <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SpinnerLoading loading />
                        </div>
                    )}
                    {this.state.is_loaded && (
                        <div>

                            <div className="monSite-breadcrumb">
                                <Breadcrumb>
                                    <BreadcrumbTitle>Paramètres</BreadcrumbTitle>
                                    <BreadcrumbBody>
                                        <BreadcrumbItem><a style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/settings`) }}>Mes paramètres</a></BreadcrumbItem>
                                        <BreadcrumbItem>Contenu du site</BreadcrumbItem>
                                        <BreadcrumbItem active>Avant / Après</BreadcrumbItem>
                                    </BreadcrumbBody>
                                </Breadcrumb>
                                <div>
                                    {this.state.subdomain !== null && (
                                        <Button
                                            style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                            outline
                                            color="primary"
                                            onClick={() => window.open(`https://${this.state.subdomain}.${this.state.DOMAINE}`, "_blank")}
                                        >
                                            Visualiser
                                        </Button>
                                    )}
                                    <Button
                                        style={{ marginLeft: "0.5rem", marginRight: "0" }}
                                        // outline
                                        color="primary"
                                        // className="btn-outline"
                                        onClick={() => {
                                            this.postPictures()
                                        }}
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </div>
                            <Row>
                                <Col xs="3">
                                    <Card style={{ margin: 0 }}>
                                        <SideMenu />
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ margin: 0 }}>
                                        <h3>{Store.getState()?.workplace?.workplace === "clinique" ? "Images de la clinique" : (Store.getState()?.workplace?.workplace === "cabinet" ? "Images du cabinet" : (Store.getState()?.workplace?.workplace === "centre de santé" ? "Images du centre de santé" : "Images"))}</h3>
                                        <p>
                                            Chargez vos images
                                        </p>
                                        <Row>
                                            <Col>
                                                <MultiImagePicker
                                                    initialState={this.state.imagesData}
                                                    setImagesData={(imagesData) => this.setState({
                                                        imagesData,
                                                    })}
                                                    imagesData={this.state.imagesData}
                                                    onRemove={() => { }}
                                                    selectOptions={this.state.attributes}
                                                    selectProps={{
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Le nom de la photo ici ..."
                                                    }}
                                                    desc="Sélectionnez ou Glissez-déposez vos photos du cabinet ici .."
                                                ></MultiImagePicker>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default HealthCareEstablishmentImage
