import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthenticationChecker from "./helpers/AuthenticationChecker/AuthenticationChecker";
import Header from "./helpers/Header/Header";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <AuthenticationChecker>
            <Component {...props} />
          </AuthenticationChecker>
        );
      }}
    />
  );
};