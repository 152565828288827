const initialState = { userData: null }

function userData(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_USER_DATA":
            nextState = {
                ...state,
                userData: action.value
            }
            return nextState || state;
        default:
            return state;
    }
}
export default userData;